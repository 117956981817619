import orders from './orders';
import artists from './artist';
import coupons from './coupon';
import samplesongs from './samplesongs';
import youtube from './youtube';
import prices from './prices';

const api = {
	orders,
	artists,
	coupons,
	samplesongs,
	youtube,
	prices,
};

export default api;
