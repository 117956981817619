import { Length } from 'config/orders';
import { useState, useEffect } from 'react';
import { getUSDPrice } from 'utils';
import classes from './Content.module.scss';

interface Step4Props {
	onNext: () => void;
	onBack: () => void;
	length: any;
	delivery: any;
	coupon: any;
	appliedCoupon: any;
	prices: any;
	onCouponApply: () => void;
	onCouponRemove: () => void;
}

export default function Step4(props: Step4Props) {
	const {
		length,
		delivery,
		onCouponApply,
		coupon,
		appliedCoupon,
		prices,
		onCouponRemove,
	} = props;
	const [usdPrice, setUsdPrice] = useState(0);

	const callPrice = async () => {
		const price = await getUSDPrice();
		setUsdPrice(price);
	};

	useEffect(() => {
		callPrice();
	}, []);

	const [currency, setCurrency] = useState('');

	const handleChange = (event: any) => {
		setCurrency(event.target.value as string);
	};

	let priceForLength = 0;
	let priceForDelivery = 0;

	switch (length.value) {
		case Length.VERYSHORT:
			priceForLength = prices.actual.thirtySeconds / 1.18;
			break;
		case Length.SHORT:
			priceForLength = prices.actual.oneMinute / 1.18;
			break;

		case Length.MEDIUM:
			priceForLength = prices.actual.twoMinute / 1.18;
			break;

		case Length.LONG:
			priceForLength = prices.actual.threeMinute / 1.18;
			break;
	}

	if (delivery.value === 'express') {
		priceForDelivery = prices.actual.expressDelivery / 1.18;
	}

	let total = priceForLength + priceForDelivery;
	let discountValue = 0;
	if (appliedCoupon) {
		if (appliedCoupon.type === 'fixed') {
			discountValue = appliedCoupon.value;
		} else if (appliedCoupon.type === 'percentage') {
			discountValue = (total * appliedCoupon.value) / 100;
		}
	}

	total = total - discountValue;
	let totalAfterGST = total + total * 0.18;

	return (
		<div className={classes.container}>
			<div className={classes.checkout}>
				<div className={classes.question}>
					<h3>Checkout</h3>
					<p className={classes.subtext}>
						ONCE YOU CHECKOUT YOU CANNOT PRESS "BACK" TO MAKE EDITS. Please
						double check to make sure all of your information (including names
						and pronunciations) is correct, If you add incorrect information,
						you must email us with any edits within 30 minutes of placing your
						order.
					</p>
				</div>
			</div>

			{!appliedCoupon?.value && (
				<div className={classes.question} style={{ marginBottom: 30 }}>
					<h3 style={{ marginBottom: 10 }}>Do you have a coupon?</h3>
					<div className={classes.couponInput}>
						<input
							value={coupon.value}
							onChange={coupon.onChange}
							placeholder="Enter your coupon code"
						/>
						<button onClick={onCouponApply}>Apply</button>
					</div>
				</div>
			)}

			<div className={classes.pricingSummary}>
				<h2>Pricing Summary</h2>
				<div className={classes.splitUps}>
					<div className={classes.splitUp}>
						<p>Song Length</p>
						<p>
							{' '}
							₹{priceForLength.toLocaleString()} ($
							{(priceForLength * usdPrice).toFixed(2)})
						</p>
					</div>
					<div className={classes.splitUp}>
						<p>Express Delivery</p>
						<p>
							+ ₹{priceForDelivery.toLocaleString()} ($
							{(priceForDelivery * usdPrice).toFixed(2)})
						</p>
					</div>
					<div className={classes.splitUp}>
						<p>CGST (+18%)</p>
						<p>
							+ ₹{(total * 0.18).toLocaleString()} ($
							{(total * 0.18 * usdPrice).toFixed(2)})
						</p>
					</div>
					{appliedCoupon?.value && (
						<div className={classes.splitUp}>
							<p>
								Applied Coupon ({appliedCoupon.code})
								<span
									className={classes.removeCouponButton}
									onClick={onCouponRemove}
								>
									Remove
								</span>
							</p>

							<p>
								- ₹{discountValue?.toLocaleString()} ($
								{(discountValue * usdPrice)?.toFixed(2)})
							</p>
						</div>
					)}
					<div className={classes.total}>
						<p>Total Price</p>
						<p>
							₹{totalAfterGST.toFixed(2)} ($
							{(totalAfterGST * usdPrice).toFixed(2)})
						</p>
					</div>
				</div>
			</div>

			<hr />
			<div className={classes.footer}>
				<button className={classes.moveNextButton} onClick={props.onBack}>
					Back
				</button>
				<button className={classes.moveNextButton} onClick={props.onNext}>
					Checkout
				</button>
				<p className={classes.termsandconditions}>
					By pressing "Checkout" you are agreeing to Melotales's Terms &
					Conditions
				</p>
			</div>
		</div>
	);
}
