import classes from './AboutUs.module.scss';
import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';
import { useHref, useNavigate } from 'react-router-dom';

import customers from 'assets/images/aboutus/customers.png';
import melody from 'assets/images/aboutus/melody.png';

export default function AboutUs() {
	const navigate = useNavigate();

	const navigateToContactUs = () => {
		navigate('/contact-us');
	};
	const navigateTolinkdin1 = () => {
		navigate('/contact-us');
	};
	const navigateTolinkdin2 = () => {
		useHref('https://www.linkedin.com/in/aishwarya-jaiswal-pm/');
	};

	return (
		<div className={classes.page}>
			<Navbar />
			<div className={classes.content}>
				<div className={classes.box}>
					<div className={classes.top}>
						<div>
							<h2>Who we are?</h2>
							<p>
								Melotales is owned by Anmol Texo Fab. We serve you with professional artists and faultless
								lyrics. We are established with a motto to innovate the best
								gifts in the frame of customised songs for all your occasions.
								Share a brief note of your feelings with us, and we will create
								a perfect soulful musical gift. Don't miss out on the chance to
								create a "WOW" moment for someone special.
							</p>

							<div>
								<h2 className={classes.nameFounder}>Meet Our Founders</h2>
								<div>
									<div className={classes.founderImgHolder}>
										<div className={classes.imgHolder}>
											<img
												className={classes.founderImg}
												src="https://media.licdn.com/dms/image/D4D03AQEwX8Pgptd1ww/profile-displayphoto-shrink_800_800/0/1689407277280?e=1728518400&v=beta&t=pHPe9fJOigOFZ6YE6jmidL4kDLDuuJ9Suv33ifCNDeo"
												alt="profilePic"
											/>

											<a href="https://www.linkedin.com/in/aayush-juneja-144847211/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
												<button className={classes.btnwithImg}>
													<img
														src="https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png"
														alt="Twitter Logo"
														className={classes.twitterlogo}
													/>{' '}
													Aayush Juneja
												</button>
											</a>
										</div>
										<div className={classes.imgHolder}>
											<img
												className={classes.founderImg}
												src="https://media.licdn.com/dms/image/D4D03AQG-tqf0dEl6og/profile-displayphoto-shrink_800_800/0/1688400588540?e=1728518400&v=beta&t=odVkQn4tRnGI9VU1pooealMTbN8fqowY0dcTrKVTEEY"
											/>
											<a href="https://www.linkedin.com/in/aishwarya-jaiswal-pm/">
												<button className={classes.btnwithImg}>
													<img
														src="https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png"
														alt="Twitter Logo"
														className={classes.twitterlogo}
													/>{' '}
													Aishwarya Jaiswal
												</button>
											</a>
										</div>
									</div>
								</div>
							</div>
							<hr />
						</div>
						<img src={customers} />
					</div>
					<hr />

					<div className={classes.bottom}>
						<div>
							<h2 style={{ marginTop: 70 }}>Why we are?</h2>
							<p>
								Melotales is designed to sweeten your special occasions. There
								will be a few junctures close to your heart like your Wedding,
								Anniversary, Parent's Birthday, Dream Proposal, Alumni Reunion,
								Farewell or any occasion close to your heart. Customise a song
								as the best-personalised gift ever for the big day. At Melotales
								, with the magical crew of artists, we deliver the zest of your
								feelings for your loved ones through the memorable and unique
								musical track.
							</p>
						</div>
						<img src={melody} />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
