import classes from './Content.module.scss';
import HappyState from 'assets/images/happy.png';
import { useNavigate } from 'react-router-dom';

export default function Step5() {
	const navigate = useNavigate();
	const moveToTrackOrder = () => {
		navigate('/track-order');
	};
	return (
		<div className={classes.centerContainer}>
			<img src={HappyState} />
			<p className={classes.thankyou}>Thank you for your order</p>
			<p>You can always track the progress of your order clicking here.</p>
			<button className={classes.moveNextButton} onClick={moveToTrackOrder}>
				Track Order
			</button>
		</div>
	);
}
