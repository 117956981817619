import classes from './Terms.module.scss';
import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';

export default function PrivacyPolicy() {
	return (
		<div className={classes.page}>
			<Navbar />
			<div className={classes.content}>
				<div className={classes.header}>
					<h2>Terms and Conditions</h2>
				</div>
				<div className={classes.box}>
					<h3>
						Melotales Music Entertainment Private Limited CUSTOMER TERMS OF USE
					</h3>
					<p>
						These Terms of Use, along with the information and policies
						contained in the Privacy Policy, , and any other documents referred
						to herein (collectively, these “Terms”) set out the legal terms and
						conditions applicable to your (“You” or “Your”) use of our website
						melotales.com (the “Site”), any of its subdomains, and any other
						websites operated by our company or on our behalf and any other
						services we offer.
					</p>
					<p>
						This Site is owned and operated by Melotales Music Entertainment
						Private Limited (“Melotales,” “Us,” “Our,” or “We”). You may use
						this Site only subject to Your acceptance of and compliance with
						these Terms. If You do not agree with these Terms, You may not use
						the Site. If You use the Site, or if You order merchandise through
						the Site, We accept this as Your acknowledgement and acceptance of
						these Terms.
					</p>
					<p>
						You acknowledge that Your use of the Site is at Our discretion, and
						Your license to use the Site may be terminated by Us at any time. We
						reserve the right, at Our sole discretion, to refuse service, to
						terminate any user’s account, and to alter or delete any material
						submitted to the Site through a user’s account.
					</p>
					<h3>CHANGES TO THESE TERMS</h3>
					<p>
						Any changes to these Terms will be included in a revised version of
						these Terms accessible through the Site. If You do not accept any
						revisions made to these Terms, You must stop using the Site.
					</p>
					<h3>PURPOSE OF THE SITE</h3>
					<p>
						The purpose of the Site is to allow you to commission an original
						song (“Song”) from a third-party Music Artist (“Music Artist”) for
						your personal use (the “Services”). You may also apply to become a
						Music Artist and to receive compensation for composing Songs for
						customers of Melotales Music Entertainment Private Limited.
					</p>
					<h3>RESTRICTIONS ON YOUR USE OF THE SITE</h3>
					<p>
						We grant You a limited, nonexclusive and revocable license to make
						use of the Site. You agree to use this Site only for lawful purposes
						and in accordance with these Terms. Your use of this Site may be
						terminated by Us at Our discretion. We reserve the right to
						determine, limit, provide, and withdraw access to the Site. This
						Site may not be used by children under the age of 18. Without
						limiting the foregoing, You specifically agree that You shall not:
						(a) submit information that is untrue, harassing, abusive,
						threatening, advertising, soliciting, advocating an illegal
						activity, infringing, or in violation of any law (b) access,
						download or copy any other user’s account information (c) bypass any
						technical measures used to prevent or restrict access to any portion
						of the Site (d) violate or attempt to violate the security of the
						Site (e) interfere with or attempt to interfere with the proper
						working of the Site (f) engage in any unauthorized use of content
						and materials included on the Site which, unless otherwise noted, is
						owned by Us (g) make commercial use of the Site beyond the uses
						contemplated herein or modify, adapt, translate, copy, reproduce,
						imitate, distribute, publish or resell the Site or any of the
						content on the Site (h) use any data mining, robots, or similar
						automated data gathering and extraction tools to access the Site (i)
						reverse engineer, decompile or disassemble the Site, or convert into
						human readable form any of the contents of this Site not intended to
						be so read.
					</p>
					<h3>SUBMITTED INFORMATION AND CAN-SPAM</h3>
					<p>
						You agree and warrant that all information You provide to Us
						directly and/or through this Site, including but not limited to any
						contact information, is truthful and accurate. We reserve the right
						to send You informational email messages about the Site. You agree
						that any and all communications sent to You electronically via the
						e-mail address you provide to Us will satisfy any legal requirement
						that such communications be in writing.
					</p>
					<h3>SUBMISSION OF CONTENT AND USER ACTIVITY</h3>
					<p>
						You understand that all information, communications, data, text,
						photographs, scans, or other materials that are uploaded, submitted,
						posted, emailed, transmitted or otherwise made available on or
						through the Site (collectively, “Content”) are the sole
						responsibility of the person or company from which such Content
						originated. This means that You, and not Melotales Music
						Entertainment Private Limited, are entirely responsible for all
						Content that originates from You.
					</p>
					<p>
						You grant the following license with respect to any and all Content:
						You hereby expressly grant to Melotales Music Entertainment Private
						Limited a royalty-free, perpetual, non-exclusive, irrevocable right
						and license to use, reproduce, adapt, modify, publish, edit,
						translate, perform, transmit, exploit, sublicense or otherwise
						distribute and display the Content submitted in connection with the
						Site, any Song created by or for you through the Site, and the
						Site’s advertising, without restriction and without compensation of
						any kind to You, and You waive all moral rights in all such Content.
					</p>
					<p>
						You represent and warrant that You own or otherwise control all the
						rights to any Content that originates from You; that use of the
						Content You provide does not violate the intellectual property
						rights or any other rights of any third parties, and that use of the
						Content You provide will not cause injury to any person or entity.
						In line with, and without limiting, the foregoing, You specifically
						agree that You will not: (a) provide any Content that is unlawful
						(according to local, state, federal or international law) or any
						Content that advocates illegal activity; (b) provide any Content
						that is defamatory, false or libelous, or that contains unlawful,
						harmful, threatening, harassing, discriminatory, abusive, profane,
						pornographic or obscene material; (c) provide any Content that You
						do not have a right to provide under law or under a contractual or
						fiduciary relationship; (d) provide any Content that contains
						software viruses or other harmful devices; or (e) impersonate any
						other person or entity or forge headers or otherwise manipulate
						identifiers in order to disguise the origin of any Content You
						provide.
					</p>
					<p>
						You acknowledge, consent and agree that Melotales Music
						Entertainment Private Limited may any Content You submit to third
						parties if required to do so by law or in a good faith belief that
						such access preservation or disclosure is reasonably necessary to:
						(a) comply with legal process; (b) enforce this Agreement; (c)
						respond to claims that any Content violates the rights of
						third-parties; (d) respond to Your requests for customer service; or
						(e) protect the rights, property, or personal safety of Melotales
						Music Entertainment Private Limited, its affiliates, its personnel,
						other users and the public.{' '}
					</p>
					<p>
						Melotales Music Entertainment Private Limited does not guarantee the
						accuracy, integrity or quality of any Content.You understand that by
						using the Site, You may be exposed to Content that is inaccurate,
						offensive, indecent or objectionable. Under no circumstances will
						Melotales Music Entertainment Private Limited be liable in any way
						for any Content, including, but not limited to, any errors or
						omissions in any Content, or for any loss or damage of any kind
						incurred as a result of the use of any Content posted, emailed,
						transmitted or otherwise made available via the Site and/or
						associated Services.
					</p>
					<p>
						The opinions expressed in Content and on this Site are not
						necessarily those of Melotales Music Entertainment Private Limited
						or its content providers, advertisers, sponsors, customers,
						affiliates or related entities. Melotales Music Entertainment
						Private Limited makes no representations or warranties regarding any
						information or opinions posted to or otherwise included on or
						transmitted through the Site. Melotales Music Entertainment Private
						Limited does not represent or guarantee the truthfulness, accuracy,
						or reliability of any Content or determine whether the Content
						violates the rights of others. You acknowledge that any reliance on
						material posted, submitted, or otherwise made available through the
						Site by others will be at Your own risk.
					</p>
					<p>
						You acknowledge that Melotales Music Entertainment Private Limited
						is not obligated to pre-screen, regularly review, monitor, delete or
						edit the Content of the Site. However, Melotales Music Entertainment
						Private Limited reserves the right to do so at any time at its sole
						discretion, either itself or through its designees, for any reason
						or no reason, and to edit or delete any posting or submission with
						or without notice. Without limiting the foregoing, You agree that
						Melotales Music Entertainment Private Limited and its designees have
						the right to edit, remove, or delete any Content that violates these
						Terms or that Melotales Music Entertainment Private Limited
						otherwise finds objectionable. Melotales Music Entertainment Private
						Limited is not responsible or liable for damages of any kind arising
						from any Content, or from Melotales Music Entertainment Private
						Limited’s alteration or deletion of any Content, even when Melotales
						Music Entertainment Private Limited is advised of the possibility of
						such damages.{' '}
					</p>
					<h3>CUSTOMER SERVICES AND OBLIGATIONS</h3>
					<p>
						You will be considered a “Customer” of Melotales Music Entertainment
						Private Limited if you complete the online ordering process, submit
						the designated payment, and if We accept your order request.Upon
						receipt and acceptance of your order, an independent third-party
						Music Artist will compose an original Song based on the Content and
						order specifications (such as length, mood, and voice preference)
						you’ve provided to Us. We and the Music Artist reserve the right to
						decline any order or to not honor specific content or order requests
						that would violate these Terms. We will then deliver to You a
						digital copy of the Song in the agreed-upon format and, if
						requested, publish a copy of that song to the specified online
						platform.
					</p>
					<p>
						Upon delivery of the Song, We grant to You a perpetual, worldwide,
						royalty free, nonexclusive license to the Song for your personal use
						only. You shall have no right to copy, sell, lease, publicly
						perform, license, distribute, or otherwise grant rights in and to
						the Song to others. Additional license rights, including commercial
						use rights, may be granted by Us to You upon request. No ownership
						rights in or to the Song will be transferred, assigned, or sold to
						You unless otherwise agreed upon in writing.
					</p>
					<h3>MUSIC ARTIST SERVICES AND OBLIGATIONS</h3>
					<p>
						By submitting an application to become a Music Artist, you agree to
						these Terms and you represent and warrant that you fulfill the Music
						Artist requirements listed on the application page. If selected to
						become a Melotales Music Artist, you will be granted access to
						potential Song orders. Upon accepting a Song order, you agree to
						compose an original Song consisting of both music and lyrics that
						conforms with the details and Content in the order and by the stated
						deadline. Upon submission of a Song in accordance with these Terms
						and the order, We will remit payment to You in the agreed-upon
						amount via the agreed-upon platform. The sole compensation for
						composing a Song will be the amount stated in the order proposal and
						acceptance. No additional compensation, including royalties, shall
						be to due to You. Failure to deliver the completed Song by the
						deadline or to comply with the details and Content in the order will
						negate any requirement for payment from Us to You and We reserve the
						right to withdraw an order from any Music Artist at any time and for
						any reason or no reason. You or We may terminate your role as a
						Music Artist at any time.
					</p>
					<p>
						Upon submission of a Song in response to an order, you hereby grant
						to Us a perpetual, nonexclusive, royalty-free, worldwide right and
						license to sublicense the Song to the Customer, to post the Song to
						Spotify or other online platform of Our choice, to monetize the Song
						via the online platforms, to reproduce the song, and to publicly
						post and perform the Song to promote You and Melotals. You shall
						retain all ownership rights and title in and to the Song subject to
						any Content incorporated into a Song that is licensed from the
						Customer.
					</p>
					<p>
						By becoming a Music Artist, you hereby grant to Us the right to use
						your name, likeness, and biographical information to promote
						Melotales and your actions as a Music Artist for so long as you
						remain a Music Artist. If you cease acting as a Music Artist, all
						licenses to Songs shall remain in effect and Melotales Music
						Entertainment Private Limited shall retain the right to display your
						name in connection with Songs you composed.
					</p>
					<h3>DISCLAIMER OF WARRANTIES</h3>
					<p>
						THIS SITE, ITS CONTENT, AND ANY ASSOCIATED SERVICES OR MATERIALS ARE
						PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. MELOTALES MAKES NO
						REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
						THE OPERATION OF THIS SITE, OR THE INFORMATION, CONTENT, OR
						MATERIALS INCLUDED WITH THIS SITE OR THE SERVICES PROVIDED THROUGH
						THIS SITE. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW,
						MELOTALES DISCLAIMS ANY AND ALL IMPLIED WARRANTIES, INCLUDING BUT
						NOT LIMITED TO IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY AND
						FITNESS FOR A PARTICULAR PURPOSE, OF WORKMANLIKE EFFORT, OF
						SUITABILITY, AVAILABILITY, ACCURACY, RELIABILITY, COMPLETENESS, OR
						TIMELINESS OF CONTENT, OR OF NON-INFRINGEMENT, AS WELL AS WARRANTIES
						ARISING THROUGH COURSE OF DEALING OR USAGE OR TRADE. FURTHER,
						MELOTALES MAKES NO REPRESENTATIONS OR WARRANTIES THAT THIS SITE
						AND/OR ITS CONTENTS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR
						ERROR-FREE.
					</p>
					<p>
						BY USING THE SITE, YOU ACKNOWLEDGE THAT YOUR USE OF THE SITE IS
						SOLELY AT YOUR OWN RISK. MELOTALES DOES NOT WARRANT OR GUARANTEE
						THAT ANY COMPONENT OF THE SITE IS FREE OF VIRUSES, WORMS OR OTHER
						HARMFUL COMPONENTS. MELOTALES DOES NOT GUARANTEE THE CONTINUOUS,
						UNINTERRUPTED AND ERROR-FREE OPERATION OF THIS SITE, NOR THAT ALL
						COMMUNICATIONS WILL BE SECURE FROM ACCESS OR INTERFERENCE BY THIRD
						PARTIES.
					</p>
					<p>
						MELOTALES IS NOT LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM OR
						OTHERWISE RELATED TO YOUR USE OF OR INABILITY TO USE THIS SITE, EVEN
						IF MELOTALES HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE
						POSSIBILITY OF SUCH DAMAGES. YOU ARE SOLELY RESPONSIBLE FOR
						COMPLIANCE WITH APPLICABLE LOCAL LAWS CONNECTED WITH YOUR USE OF THE
						SITE.
					</p>
					<p>
						CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
						THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
						TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR
						LIMITATION MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
					</p>
					<h3>INDEMNIFICATION</h3>
					<p>
						You agree to indemnify, defend and hold harmless Melotales and its
						respective agents, affiliates, and their officers, directors,
						owners, and employees, from and against any claim, demand, damages,
						cost and expenses, including reasonable attorney fees, arising from
						or related to Your use of this Site and the Services provided in
						connection with the Site, or Your breach of any provision of these
						Terms or any warranty provided hereunder.
					</p>
					<h3>OWNERSHIP OF COPYRIGHTS AND TRADEMARKS</h3>
					<p>
						All trademarks, logos, and service marks displayed on the Site are
						owned by Melotales and third parties, and the Site’s trade dress is
						owned by Melotales. Excluding any user generated, submitted, or
						posted Content, which is owned by the Site’s respective users, all
						copyrightable contents of the Site, including code, materials,
						artwork, and information provided by or through the Site, is owned
						by Melotales or third parties.
					</p>
					<h3>COPYRIGHT INFRINGEMENT CLAIMS</h3>
					<p>
						Melotales respects the intellectual property rights of third
						parties, and complies with the terms of the Digital Millennium
						Copyright Act (DMCA) regarding such rights. Melotales reserves the
						right to remove access to infringing material posted to its Site.
						Such actions do not affect or modify any other rights Melotales may
						have under law or contract.
					</p>
					<p>
						If You believe that any portion of the material contained on this
						Site infringes Your copyright, notify Melotales of Your claim in
						accordance with the following procedure. We will take appropriate
						action as required by the Digital Millennium Copyright Act India.
					</p>
					<p>
						Written Notification must be submitted to this site’s Designated
						Agent:
					</p>
					<p>Melotales Music Entertainment Private Limited</p>
					<p>Huda sector 12</p>
					<p>Panipat, Haryana</p>
					<p>132103</p>
					<p>info@melotales.com</p>
					<p>
						To be effective, the Notification must be in writing and contain the
						following information:
					</p>
					<p>
						A physical or electronic signature of a person authorized to act on
						behalf of the owner of an exclusive right that is allegedly
						infringed;
					</p>
					<p>
						Identification of the copyrighted work claimed to have been
						infringed or, if multiple copyrighted works at a single online site
						are covered by a single notification, a representative list of such
						works at that site;
					</p>
					<p>
						Identification of the copyrighted work claimed to have been
						infringed or, if multiple copyrighted works at a single online site
						are covered by a single notification, a representative list of such
						works at that site;
					</p>
					<p>
						dentification of the material that is claimed to be infringing or to
						be the subject of infringing activity and that is to be removed or
						access to which is to be disabled, and information reasonably
						sufficient to permit the service provider to locate the material;
					</p>
					<p>
						Information reasonably sufficient to permit the service provider to
						contact the complaining party, such as an address, telephone number,
						and, if available, an electronic mail address at which the
						complaining party may be contacted;
					</p>
					<p>
						A statement that the complaining party has a good faith belief that
						use of the material in the manner complained of is not authorized by
						the copyright owner, its agent, or the law;
					</p>
					<p>
						A statement that the information in the notification is accurate
						and, under penalty of perjury, that the complaining party is
						authorized to act on behalf of the owner of an exclusive right that
						is allegedly infringed.
					</p>
					<p>
						Upon receipt of the written Notification containing the information
						outlined above, Melotales will:
					</p>
					<p>
						Remove or disable access to the material that is alleged to be
						infringing;
					</p>
					<p>Forward the written notification to such alleged infringer;</p>
					<p>
						Take reasonable steps to promptly notify the alleged infringer that
						it has removed or disabled access to the material.
					</p>
					<p>
						A Counter Notification will be effective if in writing, provided to
						the Designated Agent, and including substantially the following:
					</p>
					<p>A physical or electronic signature of the alleged infringer;</p>
					<p>
						Identification of the material that has been removed or to which
						access has been disabled and the location at which the material
						appeared before it was removed or access to it was disabled;
					</p>
					<p>
						A statement under penalty of perjury that the alleged infringer has
						a good faith belief that the material was removed or disabled as a
						result of mistake or misidentification of the material to be removed
						or disabled;
					</p>
					<p>
						The alleged infringer’s name, address, and telephone number, and a
						statement that the alleged infringer consents to jurisdiction of
						District Court for the judicial district in which the address is
						located or, if outside of the India, for any judicial district in
						which Melotales may be found, and that the alleged infringer will
						accept service of process from the person who provided notification
						or the agent of such person.
					</p>
					<p>
						Upon receipt of a Counter Notification containing the information
						outlined above, Melotales will:
					</p>
					<p>
						promptly provide the complaining party with a copy of the Counter
						Notification;
					</p>
					<p>
						inform the complaining party that it will replace the removed
						material within ten (10) to fourteen (14) business days following
						the receipt of the Counter Notification, provided Melotales’s
						Designated Agent has not received notice from the complaining party
						that an action has been filed seeking a court order to restrain
						alleged infringer from engaging in infringing activity relating to
						the material on Melotales’s network or system.
					</p>
					<p>
						This process only relates to reporting a claim of copyright
						infringement. Messages related to other matters will not receive a
						response through this process.
					</p>
					<h3>PRIVACY POLICY</h3>
					<p>
						You acknowledge that You have read and agreed to Our Privacy Policy.
					</p>
					<h3>THIRD PARTY SITES</h3>
					<p>
						This Site may link You to other sites on the Internet (“Linked
						Sites”). The Linked Sites are not under Melotales’s control, and
						Melotales is not responsible for the contents of any Linked Sites,
						including without limitation the accuracy, copyright compliance,
						legality, or decency of the Linked Sites or any links contained
						therein. These links are provided for Your convenience, and do not
						imply an endorsement of the Linked Sites by Melotales or any
						association of its operators.
					</p>
					<h3>SEVERABILITY</h3>
					<p>
						Each of the sections and paragraphs of these Terms and Conditions
						operates on it’s own. If any court of law or relevant authority
						deems any of them as unlawful or unenforceable, the remaining
						sections and paragraphs will remain in full force and effect.
					</p>
					<h3>ASSIGNABILITY</h3>
					<p>
						These Terms, and any rights and licenses granted hereunder, may not
						be assigned, transferred, delegated, or sublicensed by You, but may
						be assigned, transferred, delegated, or sublicensed by Us without
						restriction.
					</p>
					<h3>WAIVER AND FORCE MAJEURE</h3>
					<p>
						Failure by Us to enforce these Terms does not constitute a waiver of
						Our rights under these Terms. In addition, Melotales will not be
						liable or responsible for any failure to perform, or delay in
						performance of, any of Our obligations to You if such failure or
						delay is caused by an event outside of Our control. An event outside
						of Our control means any act or event beyond Our or the Partners
						reasonable control such as act(s) of God, wars, terrorist attacks,
						embargoes, thefts, riots, strikes, lock-outs, trade disputes, fires,
						floods, earthquakes or other natural disasters, break-down,
						inclement weather, interruption of transport, Government action or
						failure of public or private telecommunications or transport
						networks.
					</p>
					<h3>RESOLUTION OF DISPUTES</h3>
					<p>
						The laws of the State of Haryana (Panipat Jurisdiction), India ,
						will govern these Terms and any dispute of any sort that may arise
						between Us. Regardless of where You access this Site, You agree that
						any action arising out of Your use of the Site must be brought in
						the state or federal courts serving Haryana, (Panipat Jurisdiction),
						India, and You hereby irrevocably and unconditionally consent to the
						exclusive personal jurisdiction of such courts.
					</p>
					<h3>REFUND AND REVISION POLICY </h3>
					<p>
						Every song is custom made to-order and cannot be reused by another
						customer, so unfortunately we cannot offer refunds.
					</p>
					<p>
						Music is highly subjective, and sometimes there is something that
						the customer just doesn't enjoy. We handle this on a case-by-case
						basis. Here are things that we promise to deliver:
					</p>
					<ul>
						<li>Good quality recording</li>
						<li>Song meets the ordered length</li>
						<li>Vocals are on key</li>
						<li>Lyrics contains some of your story</li>
					</ul>
					<p>
						If the revision involves changes that are based on music
						taste/preference, then we will charge a fee for the changes,
						depending on the extent, to compensate our artist for their time. ​
					</p>
					<h3>CONTACT INFORMATION</h3>
					<p>
						If You have any questions regarding these Terms, please contact
						Melotales at:
					</p>
					<p>Melotales Music Entertainment Private Limited</p>
					<p>info@melotales.com</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}
