import classes from './Hero.module.scss';
import { pricingData } from './pricingCardData';
import PricingCard from './PricingCard';

export default function Hero() {
	return (
		<div className={classes.hero}>
			<div className={classes.mainText}>
				<h1>
					<span>
						Gift Custom and Personalised
						<br />
						Songs to your Loved Ones
					</span>
				</h1>
			</div>
			<p className={classes.subText}>
				Our Musicians create ORIGINAL & STUDIO QUALITY songs based on your story
				for occasions like weddings, birthdays, proposals and many more.
			</p>
			<div className={classes.pricingCardContainer}>
				{pricingData.map((data) => {
					return <PricingCard pricingData={data} />;
				})}
			</div>
		</div>
	);
}
