import classes from './RefundPolicy.module.scss';
import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';
import { useNavigate } from 'react-router-dom';

export default function RefundPolicy() {
	const navigate = useNavigate();

	const navigateToContactUs = () => {
		navigate('/contact-us');
	};

	return (
		<div className={classes.page}>
			<Navbar />
			<div className={classes.content}>
				<div className={classes.header}>
					<h2>Download and Refund Policy</h2>
				</div>
				<div className={classes.box}>
					<p>
						At Melotales, the only objective is to make the customer happy. The
						personalized songs are currently delivered either by Internet
						download or email only. After your purchase has been approved, we
						will process within 12 hours, and will be completed between 3-7
						days. You shall receive a confirmation email on your registered
						email address once your order has been processed. This email will
						serve as your electronic purchase receipt and will contain the
						information you need to access your purchase.
					</p>
					<p>
						Downloads from our servers are closely monitored to ensure you are
						able to successfully access our products. While we are flexible and
						allow you to complete a reasonable number of downloads, we will not
						tolerate download abuse. We reserve the right to terminate your
						access to our download servers as per our discretion. We stand
						behind our songs and your satisfaction and happiness are important
						to us. However, because our products are digital goods delivered via
						Internet download, we generally offer no refunds.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}
