import Navbar from 'components/Common/Navbar';
import Hero from 'components/Home/1-Hero';
import Why from 'components/Home/2-Why';
import Process from 'components/Home/3-Process';
import Ocassions from 'components/Home/4-Ocassions';
import Samples from 'components/Home/5-Samples';
import Testimonial from 'components/Home/7-Testimonial';
import GiftNow from 'components/Home/8-GiftNow';
import Footer from 'components/Common/Footer';
import MetaData from 'utils/MetaData';

export default function Home() {
	return (
		<div style={{ marginBottom: 100 }}>
			<MetaData
				title="Musical Gifts | Personalized Custom Song - Melotales"
				keywords=""
				description="Get Musical Gifts from Melotales and explore the growth opportunities of Personalized Custom Songs for commercial or marketing purposes or for gifting too."
			/>
			<Navbar />
			<Hero />
			<Why />
			<Process />
			<Ocassions />
			<Samples />
			<Testimonial />
			<GiftNow />
			<Footer />
		</div>
	);
}
