export const ERROR_CODES = {
	NOT_AUTHORIZED: 'NOT_AUTHORIZED',
	TOKEN_EXPIRED: 'TOKEN_EXPIRED',
	ADMIN_ALREADY_EXISTS: 'ADMIN_ALREADY_EXISTS',
	SUPER_ADMIN_CANNNOT_BE_DELETED: 'SUPER_ADMIN_CANNNOT_BE_DELETED',
	ARTIST_ALREADY_EXISTS: 'ARTIST_ALREADY_EXISTS',
	COUPON_ALREADY_USED: 'COUPON_ALREADY_USED',
	COUPON_NOT_FOUND: 'COUPON_NOT_FOUND',
	ORDER_NOT_FOUND: 'ORDER_NOT_FOUND',
};

export const ERROR_MESSAGES = {
	[ERROR_CODES.NOT_AUTHORIZED]: 'You are not authorized to perform this action',
	[ERROR_CODES.TOKEN_EXPIRED]: 'Session expired. Please login again.',
	[ERROR_CODES.ADMIN_ALREADY_EXISTS]: 'Admin already exists',
	[ERROR_CODES.SUPER_ADMIN_CANNNOT_BE_DELETED]: 'Super admin cannot be deleted',
	[ERROR_CODES.ARTIST_ALREADY_EXISTS]: 'Artist already exists',
	[ERROR_CODES.COUPON_ALREADY_USED]: 'Coupon already used',
	[ERROR_CODES.COUPON_NOT_FOUND]: "Coupon doesn't exist",
	[ERROR_CODES.ORDER_NOT_FOUND]: 'Order Not Found',
};
