// assets
import Step1 from 'assets/images/process/step-1.png';
import Step2 from 'assets/images/process/step-2.png';
import Step3 from 'assets/images/process/step-3.png';
import Step4 from 'assets/images/process/step-4.png';

// styles
import classes from './Process.module.scss';

export default function Process() {
	return (
		<div className={classes.process}>
			<h1 className={classes.header}>How to Create a Custom Song ?</h1>
			<div className={classes.steps}>
				<Step
					number={1}
					title="Choose Your Artist"
					description="Select an artist or we can help match you with one. All of our artists are professional and melodious. Also, Choose the language for your song."
					image={Step1}
				/>
				<Step
					number={2}
					title="Provide Some Direction"
					description="Tell us about the occasion. Let us know who the song is for and choose a mood. Select the length of the song and tell us when can we deliver it."
					image={Step2}
				/>
				<Step
					number={3}
					title="Share Your Story"
					description="Tell us about your relationship and share some of your beautiful memories together. Never worry about the perfection of words, just give us your feelings, Melotales will compose soulful music."
					image={Step3}
				/>
			</div>
		</div>
	);
}

interface StepProps {
	number?: number;
	title?: string;
	description?: string;
	image?: string;
}

const Step = (props: StepProps) => {
	return (
		<div className={classes.step}>
			<div>
				<p className={classes.number}>Step {props.number}</p>
				<h3 className={classes.title}>{props.title}</h3>
				<p className={classes.description}>{props.description}</p>
			</div>
			<img src={props.image} className={classes.image} />
		</div>
	);
};
