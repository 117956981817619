import classes from './Samples.module.scss';
import play from 'assets/icons/play.svg';
import pause from 'assets/icons/pause.svg';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-alice-carousel';
import api from 'api';
import { toast } from 'react-toastify';

const toHHMMSS = (secs: number) => {
	var sec_num = secs;
	var hours = Math.floor(sec_num / 3600);
	var minutes = Math.floor(sec_num / 60) % 60;
	var seconds = Math.floor(sec_num % 60);

	return [hours, minutes, seconds]
		.map((v) => (v < 10 ? '0' + v : v))
		.filter((v, i) => v !== '00' || i > 0)
		.join(':');
};

export default function Samples() {
	const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
	const [tracks, setTracks] = useState([]);
	const [songs, setSongs] = useState<any>({});
	const songsRef = useRef<any>({});

	useEffect(() => {
		const fetchSongs = async () => {
			const song: any = {};
			const { success, response } = await api.samplesongs.list();
			if (!success) return;

			setTracks(response.sampleSongs);
			response.sampleSongs.map((track: any) => {
				const audio = new Audio(track.url);

				audio.onloadedmetadata = (a) => {
					setSongs((current: any) => ({
						...current,
						[track._id]: {
							...current[track._id],
							trackDuration: audio.duration,
						},
					}));
				};

				song[track._id] = {
					audio,
					playing: false,
					trackProgress: 0,
					trackDuration: isNaN(audio.duration) ? 0 : audio.duration,
				};
			});

			setSongs(song);
		};
		fetchSongs();
	}, []);

	const onScrub = (id: string, value: any) => {
		const song = songs[id];
		clearInterval(song.interval);
		song.audio.currentTime = value;
		setSongs((current: any) => ({
			...current,
			[id]: { ...song, trackProgress: song.audio.currentTime },
		}));
	};

	const onScrubEnd = (id: string) => {
		const song = songs[id];
		clearInterval(song.interval);
		song.interval = setInterval(
			() =>
				setSongs((current: any) => ({
					...current,
					[id]: { ...song, trackProgress: song.audio.currentTime },
				})),
			1000
		);
	};

	const pauseAllSongs = () => {
		Object.keys(songs).map((id: string) => {
			const song = songs[id];
			if (!song.audio) return;
			song.audio.pause();
			song.playing = false;
			clearInterval(song.interval);
			setSongs({ ...songs, [id]: song });
		});
	};

	const playSong = (id: string) => {
		pauseAllSongs();
		const song = songs[id];
		if (!song.audio) return;
		song.audio.play();
		song.playing = true;

		const trackInterval = setInterval(() => {
			setSongs((current: any) => ({
				...current,
				[id]: {
					...current[id],
					trackProgress: current[id].audio.currentTime,
					trackDuration: current[id].audio.duration,
				},
			}));
		}, 1000);

		setSongs((current: any) => ({
			...current,
			[id]: { ...current[id], playing: true, interval: trackInterval },
		}));
	};

	const pauseSong = (id: string) => {
		const song = songs[id];
		if (!song.audio) return;
		song.audio.pause();
		song.playing = false;
		clearInterval(song.interval);
		setSongs((current: any) => ({
			...current,
			[id]: { ...current[id], playing: false },
		}));
	};

	useEffect(() => {
		songsRef.current = songs;
	}, [songs]);

	useEffect(() => {
		return () => {
			Object.keys(songsRef.current).map((id: string) => {
				const song = songsRef.current[id];
				if (!song.audio) return;
				song.audio.pause();
				song.playing = false;
				clearInterval(song.interval);
			});
		};
	}, []);

	const splitTracks: any[] = [];
	tracks.map((track: any, index: number) => {
		if (index % 3 === 0) {
			splitTracks.push([]);
		}
		splitTracks[splitTracks.length - 1].push(track);
	});

	const items = splitTracks.map((tracks: any[]) => {
		return tracks.map((track: any) => (
			<Song
				key={track._id}
				{...track}
				song={songs[track._id]}
				playSong={playSong}
				pauseSong={pauseSong}
				onScrub={onScrub}
				onScrubEnd={onScrubEnd}
			/>
		));
	});

	if (tracks.length === 0) return null;

	return (
		<div className={classes.samples}>
			<h2 className={classes.title}>
				Want to <span>Hear?</span>
			</h2>
			<div className={classes.songs}>
				<Carousel
					infinite
					swipeDelta={200}
					items={items}
					activeIndex={carouselActiveIndex}
					onSlideChanged={(e: any) => setCarouselActiveIndex(e.item)}
					renderDotsItem={() => null}
					renderPrevButton={() =>
						tracks.length > 3 && <p className={classes.controlButton}>{'<'}</p>
					}
					renderNextButton={() =>
						tracks.length > 3 && <p className={classes.controlButton}>{'>'}</p>
					}
				/>
			</div>
		</div>
	);
}

const Song = (props: any) => {
	const currentPercentage = props.song?.trackDuration
		? `${(props.song?.trackProgress / props.song?.trackDuration) * 100}%`
		: '0%';

	const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #f8835a55), color-stop(${currentPercentage}, #eee))
`;

	return (
		<div className={classes.song}>
			<div className={classes.details}>
				<div className={classes.textDetails}>
					<h4 className={classes.title}>{props.name}</h4>
				</div>
			</div>
			<div className={classes.progress} onClick={(e) => e.stopPropagation()}>
				<div className={classes.barControl}>
					<img
						src={props.song?.playing ? pause : play}
						alt="play"
						className={classes.play}
						onClick={() =>
							props.song?.playing
								? props.pauseSong(props._id)
								: props.playSong(props._id)
						}
					/>
				</div>
				<div className={classes.bar} onClick={(e) => e.stopPropagation()}>
					<input
						type="range"
						value={props.song?.trackProgress}
						step="1"
						min="0"
						max={props.song?.trackDuration}
						className="progress"
						onChange={(e) => props.onScrub(props._id, e.target.value)}
						onMouseUp={() => props.onScrubEnd(props._id)}
						onKeyUp={() => props.onScrubEnd(props._id)}
						style={{ background: trackStyling }}
						onDrag={(e) => e.stopPropagation()}
					/>
				</div>
				<p>
					{toHHMMSS(props.song?.trackProgress)}/
					{toHHMMSS(props.song?.trackDuration)}
				</p>
			</div>
			<div className={classes.controls}>
				<img
					src={props.song?.playing ? pause : play}
					alt="play"
					className={classes.play}
					onClick={() =>
						props.song?.playing
							? props.pauseSong(props._id)
							: props.playSong(props._id)
					}
				/>
			</div>
		</div>
	);
};
