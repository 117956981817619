import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';
import ArtistList from 'components/Artists/List';
import MetaData from 'utils/MetaData';

export default function Artists() {
	return (
		<div>
			<MetaData
				title="Top Music Artists | Best Indian Artist - Melotales"
				keywords=""
				description="Find the singers or Top Music Artists from Melotales. Listen to your Best Indian Artist songs and create high quality custom songs."
			/>
			<Navbar />
			<ArtistList />
			<Footer />
		</div>
	);
}
