export enum Language {
	ENGLISH = 'english',
	HINDI = 'hindi',
	PUNJABI = 'punjabi',
}

export enum Mood {
	HAPPY = 'happy',
	SAD = 'sad',
	ROMANTIC = 'romantic',
	REFLECTIVE = 'reflective',
	FUNNY = 'funny',
}

export enum Length {
	LONG = 'long',
	MEDIUM = 'medium',
	SHORT = 'short',
	VERYSHORT = 'veryshort',
}

export enum Delivery {
	STANDARD = 'standard',
	EXPRESS = 'express',
}

export enum Status {
	PENDING = 'pending',
	PROCESSING = 'processing',
	COMPLETED = 'completed',
	CANCELLED = 'cancelled',
}

export enum PaymentStatus {
	PENDING = 'pending',
	PAID = 'paid',
	REFUNDED = 'refunded',
	CANCELLED = 'cancelled',
}

export const Languages = [
	{ value: Language.ENGLISH, label: 'English' },
	{ value: Language.HINDI, label: 'Hindi' },
	{ value: Language.PUNJABI, label: 'Punjabi' },
];

export const customizedLanguages = [
	{ value: Language.ENGLISH, label: 'English' },
	{ value: Language.HINDI, label: 'Hindi' },
];

export const Moods = [
	{ label: 'Happy', value: Mood.HAPPY },
	{ label: 'Sad', value: Mood.SAD },
	{ label: 'Romantic', value: Mood.ROMANTIC },
	{ label: 'Reflective', value: Mood.REFLECTIVE },
	{ label: 'Funny', value: Mood.FUNNY },
];

export const customizedSong = [
	{ label: 'Happy', value: Mood.HAPPY },
	{ label: 'Sad', value: Mood.SAD },
	{ label: 'Romantic', value: Mood.ROMANTIC },
];

export const Lengths = [
	// { label: "30", value: Length.VERYSHORT, key: "thirtySeconds" },
	{ label: '1', value: Length.SHORT, key: 'oneMinute' },
	{ label: '2', value: Length.MEDIUM, key: 'twoMinute' },
	{ label: '3-4', value: Length.LONG, key: 'threeMinute' },
];

export const jingleLengths = [
	{ label: '1', value: Length.SHORT, key: 'jingle', plan: 'pro' },
	{
		label: '2',
		value: Length.MEDIUM,
		key: 'jingle_twoMinute',
		plan: 'premium',
	},
];

export const Deliveries = [
	{
		label: 'Standard (6 days)',
		value: Delivery.STANDARD,
		key: 'standardDelivery',
	},
	{
		label: 'Express (3 days)',
		value: Delivery.EXPRESS,
		key: 'expressDelivery',
	},
];

export const JingleDeliveries = [
	{
		label: 'Standard (6 days)',
		value: Delivery.STANDARD,
		key: 'standardDelivery',
	},
	{
		label: 'Express (3 days)',
		value: Delivery.EXPRESS,
		key: 'expressDelivery',
	},
];

export const Statuses = [
	{ label: 'Pending', value: Status.PENDING },
	{ label: 'Processing', value: Status.PROCESSING },
	{ label: 'Completed', value: Status.COMPLETED },
	{ label: 'Cancelled', value: Status.CANCELLED },
];

export const PaymentStatuses = [
	{ label: 'Pending', value: PaymentStatus.PENDING },
	{ label: 'Paid', value: PaymentStatus.PAID },
	{ label: 'Refunded', value: PaymentStatus.REFUNDED },
	{ label: 'Cancelled', value: PaymentStatus.CANCELLED },
];
