import classes from './PrivacyPolicy.module.scss';
import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';

export default function PrivacyPolicy() {
	return (
		<div className={classes.page}>
			<Navbar />
			<div className={classes.content}>
				<div className={classes.header}>
					<h2>Privacy Policy</h2>
				</div>
				<div className={classes.box}>
					<h3>This Privacy Policy applies to melotales.com</h3>
					<p>
						Melotales.com recognises the importance of maintaining your privacy.
						We value your privacy and appreciate your trust in us. This Policy
						describes how we treat user information we collect on
						www.melotales.com and other offline sources. This Privacy Policy
						applies to current and former visitors to our website and to our
						online customers. By visiting and/or using our website, you agree to
						this Privacy Policy.Melotales.com is a property of Melotales Music
						Entertainment PVT LTD.
					</p>
					<h3>Contact information, billing information & payment</h3>
					<p>
						We might collect your name, email, mobile number, phone number,
						street, city, state, pincode, country and ip address. We might
						collect your billing name, billing address and payment method when
						you buy a song. We NEVER collect your credit/debit card number or
						credit/debit card expiry date or other details pertaining to your
						credit card on our website. Credit card information will be obtained
						and processed by our online payment partner Razorpay. We collect
						information you post in a public space on our website or on a
						third-party social media site belonging to melotales.com.
					</p>
					<h3>Demographic information</h3>
					<p>
						We may collect demographic information about you, or any other
						information provided by you during the use of our website. We might
						collect this as a part of a survey also.
					</p>
					<h3>Other information</h3>
					<p>
						If you use our website, we may collect information about your IP
						address and the browser you're using. We might look at what site you
						came from, duration of time spent on our website, pages accessed or
						what site you visit when you leave us. We might also collect the
						type of mobile device you are using, or the version of the operating
						system your computer or device is running.
					</p>
					<h3>We collect information in different ways</h3>
					<p>
						We also collect information if you post a comment on our websites or
						ask us a question through phone or email. We use tracking tools like
						Google Analytics, Google Webmaster, browser cookies and web beacons
						for collecting information about your usage of our website.
					</p>
					<h3>We get information about you from third parties</h3>
					<p>
						The third-party social media site will give us certain information
						about you. This could include your name and email address. We use
						information to contact you. We might use the information you provide
						to contact you for confirmation of a purchase on our website or for
						other promotional purposes. We use information to respond to your
						requests or questions. We use information to improve our products
						and services. We might use your information to customize your
						experience with us. This could include displaying content based upon
						your preferences. We use information to look at site trends and
						customer interests. We may use your information to make our website
						and products better. We may combine information we get from you with
						information about you we get from third parties. We use information
						for security purposes. We may use information to protect our
						company, our customers, or our websites. We use information for
						marketing purposes. We might send you information about special
						promotions or offers. We might also tell you about new features or
						products. These might be our own offers or products, or third-party
						offers or products we think you might find interesting. We use
						information to send you transactional communications. We might send
						you emails or SMS about your account. We use information as
						otherwise permitted by law.
					</p>
					<h3>Sharing of information with third-parties</h3>
					<p>
						We will share information with third parties who perform services on
						our behalf. We share information with vendors who help us manage our
						online registration process or payment processors or transactional
						message processors. Some vendors may be located outside of India. We
						share your information with parties responsible for fulfilling the
						purchase obligation. parties may use the information we give them as
						described in their privacy policies. We will share information with
						our business partners. This includes a third party. Our partners use
						the information we give them as described in their privacy policies.
						We may share information if we think we have to in order to comply
						with the law or to protect ourselves. We will share information to
						respond to a court order or subpoena. We may also share it if a
						government agency or investigatory body requests. Or, we might also
						share information when we are investigating potential fraud. We may
						share information with any successor to all or part of our business.
						For example, if part of our business is sold we may give our
						customer list as part of that transaction. We may share your
						information for reasons not described in this policy.
					</p>
					<h3>Email Opt-Out</h3>
					<p>
						You can opt out of receiving our marketing emails. To stop receiving
						our promotional emails, please email info@melotales.com. It may take
						about ten days to process your request. Even if you opt out of
						getting marketing messages, we will still be sending you
						transactional messages through email and SMS about your purchases.
					</p>
					<h3>Third party sites</h3>
					<p>
						If you click on one of the links to third party websites, you may be
						taken to websites we do not control. This policy does not apply to
						the privacy practices of those websites. Read the privacy policy of
						other websites carefully. We are not responsible for these third
						party sites.
					</p>
					<h3>Grievance Officer</h3>
					<p>
						In accordance with Information Technology Act 2000 and rules made
						there under, the contact details of the Grievance Officer are
						provided below: If you have any questions about this Policy or other
						privacy concerns, you can also email us at info@melotales.com.
					</p>
					<h3>Updates to this policy</h3>
					<p>
						This Privacy Policy was last updated on April 1, 2022. From time to
						time we may change our privacy practices. We will notify you of any
						material changes to this policy as required by law. We will also
						post an updated copy on our website. Please check our site
						periodically for updates.
					</p>
					<h3>Jurisdiction</h3>
					<p>
						If you choose to visit the website, your visit and any dispute over
						privacy is subject to this Policy and the website's terms of use. In
						addition to the foregoing, any disputes arising under this Policy
						shall be governed by the laws of India.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}
