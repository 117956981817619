import Navbar from 'components/Common/Navbar';
import Wizard from 'components/CreateSong/Wizard';
import Footer from 'components/Common/Footer';
import MetaData from 'utils/MetaData';

export default function CreateSong() {
	return (
		<div>
			<MetaData
				title="Make Music Online | Best Custom Song maker - Melotales"
				keywords=""
				description="Make Music Online easily and  build your own Custom Song at leading music creation platform. Melotales provide all kind of music composition."
			/>
			<Navbar />
			<Wizard />
			<Footer />
		</div>
	);
}
