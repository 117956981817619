import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';
import TrackOrderWizard from 'components/TrackOrder';
import MetaData from 'utils/MetaData';

export default function TrackOrder() {
	return (
		<div>
			<MetaData
				title="Music Track Online - Melotales"
				keywords=""
				description=""
			/>
			<Navbar />
			<TrackOrderWizard />
			<Footer />
		</div>
	);
}
