import classes from './PickArtistBox.module.scss';
import play from 'assets/icons/play.svg';
import pause from 'assets/icons/pause.svg';
import { useEffect, useRef, useState } from 'react';
import useInput from 'hooks/useInput';

const artists = [
	{
		name: 'Select your artist',
		value: '',
		songs: [],
	},
	{
		name: 'Male',
		value: 'Male',
		songs: [
			// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/demo-songs-for-website/male_1.wav',
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/demo-songs-for-website%2Fmale-1.mp3?alt=media&token=2a6e7616-fca3-4fc4-a494-b027de7e33d7',
			// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/demo-songs-for-website/male_2.wav',
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/demo-songs-for-website%2Fmale-2.mp3?alt=media&token=34080e97-4440-4cec-9222-50a23985db99',
			// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/demo-songs-for-website/male_3.mp3',
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/demo-songs-for-website%2Fmale_3.mp3?alt=media&token=2d967190-1730-4039-9775-8f1ba86d2782',


		],
	},
	{
		name: 'Female',
		value: 'Female',
		songs: [
			// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/demo-songs-for-website/female_1.wav',
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/demo-songs-for-website%2Ffemale-1.mp3?alt=media&token=101b371a-b4a9-450a-9a98-3f76f45678ed',
			// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/demo-songs-for-website/female_2.wav',
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/demo-songs-for-website%2Ffemale-2.mp3?alt=media&token=81501425-1995-4e26-8009-0d0599af2845',
		],
	},
];

export default function PickArtistBox(props: any) {
	const { artistType } = props;

	const [songs, setSongs] = useState<string[]>([]);
	const currentSong = useInput('');

	const handleChange = (e: any) => {
		const artist = artists.filter(
			(_artist) => _artist.value === e.target.value
		)[0];

		artistType.set(artist.value);
		setSongs(artist.songs);
	};

	useEffect(() => {
		const artist = artists.filter(
			(_artist) => _artist.value === artistType.value
		)[0];

		artistType.set(artist.value);
		setSongs(artist.songs);
	}, []);

	return (
		<div className={classes.pickArtistContainer}>
			<div className={classes.pickArtist}>
				<select value={artistType.value} onChange={handleChange}>
					{/* <option value=''>Select your artist</option> */}
					{artists.map((artist) => {
						return (
							<option key={artist.name} value={artist.value}>
								{artist.name}
							</option>
						);
					})}
				</select>

				{songs.map((song) => (
					<Song url={song} currentSong={currentSong} key={song} />
				))}
			</div>
		</div>
	);
}

const Song = ({ url, currentSong }: { url: string; currentSong: any }) => {
	const [song, setSong] = useState<any>({});
	const songRef = useRef<any>({});

	useEffect(() => {
		let song: any = {};

		const audio = new Audio(url);

		audio.onloadedmetadata = (a) => {
			setSong((current: any) => ({
				...current,
				trackDuration: audio.duration,
			}));
		};

		song = {
			audio,
			playing: false,
			trackProgress: 0,
			trackDuration: isNaN(audio.duration) ? 0 : audio.duration,
		};

		setSong(song);

		return () => {
			const song = songRef.current;
			song.audio.pause();
			song.playing = false;
			clearInterval(song.interval);
		};
	}, []);

	useEffect(() => {
		songRef.current = song;
	}, [song]);

	useEffect(() => {
		if (currentSong.value !== url) pauseSong();
	}, [currentSong.value]);

	const playSong = () => {
		if (!song.audio) return;
		currentSong.set(url);
		song.audio.play();
		song.playing = true;

		const trackInterval = setInterval(() => {
			setSong((current: any) => ({
				...current,
				trackProgress: current.audio.currentTime,
				trackDuration: current.audio.duration,
			}));
		}, 1000);

		setSong((current: any) => ({
			...current,
			playing: true,
			interval: trackInterval,
		}));
	};

	const pauseSong = () => {
		if (!song.audio) return;
		song.audio.pause();
		song.playing = false;
		clearInterval(song.interval);
		setSong((current: any) => ({ ...current, playing: false }));
	};

	const onScrub = (value: any) => {
		clearInterval(song.interval);
		song.audio.currentTime = value;
		setSong((current: any) => ({
			...current,
			trackProgress: song.audio.currentTime,
		}));
	};

	const onScrubEnd = () => {
		clearInterval(song.interval);
		song.interval = setInterval(
			() =>
				setSong((current: any) => ({
					...current,
					trackProgress: song.audio.currentTime,
				})),
			1000
		);
	};

	const currentPercentage = song?.trackDuration
		? `${(song?.trackProgress / song?.trackDuration) * 100}%`
		: '0%';
	const background = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #f8835a55), color-stop(${currentPercentage}, #eee))
`;

	return (
		<div className={classes.sampleSong}>
			<div className={classes.player}>
				<img
					onClick={song?.playing ? pauseSong : playSong}
					src={song?.playing ? pause : play}
					alt="control"
				/>

				<input
					type="range"
					value={song?.trackProgress}
					step="1"
					min="0"
					max={song?.trackDuration}
					className="progress"
					onChange={(e) => onScrub(e.target.value)}
					onMouseUp={onScrubEnd}
					onKeyUp={onScrubEnd}
					style={{ background }}
				/>
			</div>
		</div>
	);
};
