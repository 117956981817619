import network from './network';

export const create = (body: any) => {
	return network.request.post({
		path: 'orders',
		body,
	});
};

export const markPaid = (id: any, transaction: any) => {
	return network.request.patch({
		path: `orders/${id}/paid`,
		body: {
			transaction,
		},
	});
};

export const get = (id: any) => {
	return network.request.get({
		path: `orders/${id}`,
	});
};

const orders = {
	create,
	markPaid,
	get,
};

export default orders;
