import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';

export default function TipsFaqs() {
	return (
		<div>
			<Navbar />
			<Footer />
		</div>
	);
}
