import { Link, useLocation } from 'react-router-dom';

// assets
import logo from 'assets/images/logos/logo.png';

// styles
import classes from './Navbar.module.scss';
import classNames from 'classnames';
import { useMemo } from 'react';
import useSwitch from 'hooks/useSwitch';
import close from 'assets/icons/close.svg';
import hamburgerMenu from 'assets/icons/hamburger-menu.svg';
import { RiWhatsappFill } from 'react-icons/ri';
// import {Bounce} from "react-reveal/Bounce";
const Bounce = require('react-reveal/Bounce');

const WhatsappIcon = () => {
	return <RiWhatsappFill style={{ color: '#075E54', marginRight: 8 }} />;
};

export default function Navbar() {
	const { pathname } = useLocation();
	const showMobileNav = useSwitch();

	const links = useMemo(
		() => [
			// {
			//   name: "Home",
			//   path: "/",
			//   active: pathname === "/",
			//   showInMobile: false,
			//   externalLink: false,
			//   id: 1
			// },
			{
				name: 'Create Song',
				path: '/create-song',
				active: pathname === '/create-song',
				showInMobile: true,
				externalLink: false,
				id: 2,
			},
			// {
			//   name: "Songs @ ₹699",
			//   path: "/custom-song",
			//   active: pathname === "/custom-song",
			//   showInMobile: true,
			//   externalLink: false,
			//   id: 3
			// },
			{
				name: 'Artists',
				path: '/artists',
				active: pathname === '/artists',
				showInMobile: true,
				externalLink: false,
				id: 4,
			},
			{
				name: 'Track Order',
				path: '/track-order',
				active: pathname === '/track-order',
				showInMobile: true,
				externalLink: false,
				id: 5,
			},
			{
				name: 'Blog',
				path: 'https://melotales.com/blog/',
				showInMobile: true,
				externalLink: true,
				id: 6,
			},
			{
				name: 'Buy Perfume',
				path: 'https://houseofamoha.in/',
				showInMobile: true,
				externalLink: true,
				id: 7,
			},
			{
				name: '+91 99961 20181',
				path: 'tel:+91 99961 20181',
				showInMobile: true,
				externalLink: true,
				id: 8,
			},
		],
		[pathname]
	);

	return (
		<>
			<nav className={classes.navbar}>
				<Link to="/" style={{ textDecoration: 'none' }}>
					<div>
						<img src={logo} style={{ height: 70 }} />
						<p className={classes.tagline}>Melody for your tales</p>
					</div>
				</Link>

				<div className={classes.navItems}>
					{links.map(({ name, path, active, externalLink, id }) =>
						!externalLink ? (
							<Link
								key={name}
								to={path}
								className={classNames({ [classes.active]: active })}
							>
								{name}
							</Link>
						) : (
							<a href={path}>
								{id === 8 ? <WhatsappIcon /> : null}
								{name}
							</a>
						)
					)}
				</div>

				<Bounce right when={!showMobileNav.value} duration={500}>
					<img
						src={hamburgerMenu}
						width={40}
						style={{ cursor: 'pointer' }}
						onClick={showMobileNav.true}
						className={classes.hamburger}
					/>
				</Bounce>

				<Bounce left when={showMobileNav.value} duration={500}>
					<div
						className={classes.mobileNavbar}
						style={{ display: showMobileNav.value ? 'inherit' : 'none' }}
					>
						<img
							src={close}
							width={10}
							onClick={showMobileNav.false}
							className={classes.closeIcon}
						/>
						{links.map(
							({ name, path, active, showInMobile, externalLink, id }) =>
								showInMobile &&
								(!externalLink ? (
									<Link
										key={name}
										to={path}
										className={classNames({ [classes.active]: active })}
									>
										{name}
									</Link>
								) : (
									<a href={path}>
										{id === 8 ? <WhatsappIcon /> : null}
										{name}
									</a>
								))
						)}
					</div>
				</Bounce>
			</nav>
		</>
	);
}