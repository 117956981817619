import { useNavigate } from 'react-router-dom';
import classes from './GiftNow.module.scss';

export default function GiftNow() {
	const navigate = useNavigate();
	const onGiftNowClick = () => {
		navigate('/create-song');
	};

	return (
		<div className={classes.giftNow}>
			<div className={classes.backgroundRect} />
			<div className={classes.overlayRect}>
				<div className={classes.left}>
					<div className={classes.title}>
						<p>GIFT A PERSONALIZED</p>
						<p>SONG TO YOUR LOVED ONES,</p>
						<p>FRIENDS AND FAMILY</p>
					</div>
					<button className={classes.actionButton} onClick={onGiftNowClick}>
						Gift Now
					</button>
				</div>
				<div className={classes.right}>
					<div className={classes.point}>
						<p className={classes.title}>ORIGINAL & CUSTOMIZED</p>
					</div>
					<hr />
					<div className={classes.point}>
						<p className={classes.title}>QUICKEST DELIVERY</p>
					</div>
					<hr />
					<div className={classes.point}>
						<p className={classes.title}>STUDIO QUALITY</p>
					</div>
				</div>
			</div>
		</div>
	);
}
