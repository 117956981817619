// assets
import api from 'api';
import { useEffect, useState } from 'react';

// styles
import classes from './Why.module.scss';

export default function Why() {
    const [videoId, setVideoId] = useState<string | null>(null);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            const { success, error, response } = await api.youtube.list();
            // console.log('API Response:', success, error, response); 
            if (success && response.links.length > 0) {
                const videoUrl = response.links[0]?.url;
                console.log('Video URL:', videoUrl); // Log video URL
                const extractedVideoId = extractVideoId(videoUrl);
                // console.log('Extracted Video ID:', extractedVideoId); 
                setVideoId(extractedVideoId);
            }
        };

        fetchVideoUrl();
    }, []);

    // Function to extract vid ID from Youuttube URL
    const extractVideoId = (url: string) => {
        const match = url.match(/[?&]v=([^?&]+)/);
        return match && match[1];
    };

    if (!videoId) return null;

    const youtubeUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div className={classes.why}>
            <div className={classes.images}>
                <iframe
                    src={youtubeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded YouTube Video"
                    style={{ width: '80%', height: '70vh', maxWidth: '100%', borderRadius: '20px' }}
                />
            </div>
        </div>
    );
}
