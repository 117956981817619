// assets
import anniversary from 'assets/images/ocassions/anniversary.jpg';
import birthday from 'assets/images/ocassions/birthday.jpg';
import goodbye from 'assets/images/ocassions/goodbye.jpg';
import justbecause from 'assets/images/ocassions/justbecause.jpg';
import memorial from 'assets/images/ocassions/memorial.jpg';
import proposal from 'assets/images/ocassions/proposal.jpg';
import retirement from 'assets/images/ocassions/retirement.jpg';
import wedding from 'assets/images/ocassions/wedding.jpg';

// styles
import classes from './Ocassions.module.scss';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';

const ocassions = [
	{ title: 'Wedding', image: wedding },
	{ title: 'Anniversary', image: anniversary },
	{ title: 'Birthday', image: birthday },
	{ title: 'Goodbye', image: goodbye },
	{ title: 'Just Because', image: justbecause },
	{ title: 'Memorial', image: memorial },
	{ title: 'Proposal', image: proposal },
	{ title: 'Retirement', image: retirement },
];

const items = ocassions.map((ocassion, index) => (
	<div className={classes.card} key={index}>
		<Link to="/create-song">
			<img
				src={ocassion.image}
				alt={ocassion.title}
				className={classes.image}
			/>
			<h3 className={classes.title}>{ocassion.title}</h3>
		</Link>
	</div>
));

export default function Ocassions() {
	return (
		<div className={classes.ocassions}>
			<div className={classes.header}>
				<h4 className={classes.supportTitle}>EXPLORE</h4>
				<h2 className={classes.title}>Occassions</h2>
			</div>
			<div className={classes.cards}>
				<AliceCarousel
					autoWidth
					infinite
					renderDotsItem={() => null}
					items={items}
					autoPlay
					autoPlayInterval={2000}
					disableButtonsControls
				/>
			</div>
		</div>
	);
}
