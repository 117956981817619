export type PricingCardData = {
	planName: string;
	description: string[];
	price: string;
	link: string;
	recommended: boolean;
	mostrecommended: boolean;
	audioUrl: string;
};

export const pricingData: PricingCardData[] = [
	{
		planName: 'Melody Pro',
		description: [
			'Partially Customisable',
			'Original and Studio Quality',
			'Duration - 1 minute',
		],
		price: '₹799',
		link: '/custom-song?plan=pro',
		recommended: false,
		mostrecommended: false,
		audioUrl:
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/999rssamplesong.mp3?alt=media&token=52f36172-3988-48b3-927a-910688ffb2a3',
	},
	{
		planName: 'Melody Premium',
		description: [
			'Partially Customisable',
			'Original and Studio Quality',
			'Duration - 2 minutes',
			'Better Story-Telling With Warm Emotions',
		],
		price: '₹1499',
		link: '/custom-song?plan=premium',
		recommended: true,
		mostrecommended: false,
		audioUrl:
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/1999rssamplesong.mp3?alt=media&token=cc0ff828-bf89-4dad-b291-5a175b0647fc',
	},
	{
		planName: 'Melody Supreme',
		description: [
			'Fully Customisable',
			'Whole Song Based On Your Story',
			'Original and Studio Quality',
			'Lyrics Approval',
			'Song Duration Options - 1, 2 & 3 minutes',
			`Platform Listing ( spotify, amazon music, etc. )`,
			'Best Personalised Experience',
		],
		price: '₹2998',
		link: '/create-song?plan=supreme',
		recommended: false,
		mostrecommended: true,
		audioUrl:
			'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/5999rssamplesong.mp3?alt=media&token=aaae3f24-8123-42ae-8f22-3a78e6d61867',
	},
];

