import network from './network';

export const get = (user: string, code: string) => {
	return network.request.get({
		path: `coupons/${user}/${code}`,
	});
};

const artists = {
	get,
};

export default artists;
