import network from './network';

export const list = () => {
	return network.request.get({
		path: 'artists/',
	});
};

export const get = (id: string) => {
	return network.request.get({
		path: `artists/${id}`,
	});
};

const artists = {
	list,
	get,
};

export default artists;
