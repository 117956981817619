// hooks
import { useCallback, useMemo, useState } from 'react';
import useInput from 'hooks/useInput';

// styles
import classes from './TrackOrder.module.scss';

// helpers
import api from 'api';

// assets
import SongStatusImage from 'assets/images/song-status.png';
import { toast } from 'react-toastify';

export default function TrackOrderWizard() {
	const orderId = useInput();
	const [order, setOrder] = useState<any>(null);

	const fetchOrder = async () => {
		const { success, response, error } = await api.orders.get(orderId.value);
		if (!success) {
			toast.error(error?.message);
			return;
		}
		setOrder(response?.order);
	};

	const clearOrder = () => {
		setOrder(null);
		orderId.set('');
	};

	const status = useMemo(() => {
		let status = '';
		switch (order?.status) {
			case 'pending-admin-accept':
			case 'pending-artist-accept':
				status = 'Pending Acceptance';
				break;

			case 'processing':
			case 'review':
				status = 'Processing';
				break;

			case 'completed':
				status = 'Completed';
				break;

			case 'rejected':
				status = 'Rejected';
				break;

			case 'cancelled-by-admin':
			case 'cancelled-by-user':
				status = 'Cancelled';
				break;
		}
		return status;
	}, [order]);

	return (
		<div className={classes.container}>
			<div className={classes.wizard}>
				<div className={classes.left}>
					<div className={classes.header}>
						<div>
							<h2 className={classes.title}>Your Songs Status</h2>
							<p className={classes.helpText}>
								Track the progress of your personalized song - and listen to it
								here when it's complete!
							</p>
						</div>
					</div>
					<div className={classes.stage}>
						{!order ? (
							<div className={classes.stageContent}>
								<input
									value={orderId.value}
									onChange={orderId.onChange}
									placeholder="Enter your order ID"
								/>
								<button onClick={fetchOrder}>Track</button>
							</div>
						) : (
							<div className={classes.statusContent}>
								<h1>ID: {order._id}</h1>
								<p>
									<strong>Status:</strong> {status}
								</p>
								<button onClick={clearOrder}>Check Another Order</button>
							</div>
						)}
					</div>
				</div>
				<div className={classes.right}>
					<img src={SongStatusImage} />
				</div>
			</div>
		</div>
	);
}
