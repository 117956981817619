import network from './network';

export const list = () => {
	return network.request.get({
		path: 'youtube-links/',
	});
};

const youtube = {
	list,
};

export default youtube;
