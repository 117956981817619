import api from 'api';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import classes from './Artist.module.scss';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// assets
import play from 'assets/icons/play.svg';
import pause from 'assets/icons/pause.svg';
import facebook from 'assets/logos/facebook.png';
import instagram from 'assets/logos/instagram.png';
import twitter from 'assets/logos/twitter.png';
import youtube from 'assets/logos/youtube.png';
import spotify from 'assets/logos/spotify.png';

export default function ArtistView() {
	const [artist, setArtist] = useState<any>([]);
	const [songs, setSongs] = useState<any>({});
	const songsRef = useRef<any>({});
	const { id } = useParams();

	useEffect(() => {
		const fetchArtist = async () => {
			const { success, error, response } = await api.artists.get(id as string);
			if (!success) return toast.error(error?.message);
			setArtist(response.artist);

			const song: any = {};
			const audio = new Audio(response.artist.sampleSongUrl);
			song[response.artist._id] = {
				audio,
				playing: false,
				trackProgress: 0,
				trackDuration: audio.duration,
			};
			setSongs(song);
		};

		fetchArtist();
	}, []);

	const pauseAllSongs = () => {
		Object.keys(songs).map((id: string) => {
			const song = songs[id];
			if (!song.audio) return;
			song.audio.pause();
			song.playing = false;
			clearInterval(song.interval);
			setSongs({ ...songs, [id]: song });
		});
	};

	const playSong = (id: string) => {
		pauseAllSongs();
		const song = songs[id];
		if (!song.audio) return;
		song.audio.play();
		song.playing = true;

		const trackInterval = setInterval(() => {
			setSongs((current: any) => ({
				...current,
				[id]: {
					...current[id],
					trackProgress: current[id].audio.currentTime,
					trackDuration: current[id].audio.duration,
				},
			}));
		}, 1000);

		setSongs((current: any) => ({
			...current,
			[id]: { ...current[id], playing: true, interval: trackInterval },
		}));
	};

	const pauseSong = (id: string) => {
		const song = songs[id];
		if (!song.audio) return;
		song.audio.pause();
		song.playing = false;
		clearInterval(song.interval);
		setSongs((current: any) => ({
			...current,
			[id]: { ...current[id], playing: false },
		}));
	};

	const onScrub = (id: string, value: any) => {
		const song = songs[id];
		clearInterval(song.interval);
		song.audio.currentTime = value;
		setSongs((current: any) => ({
			...current,
			[id]: { ...song, trackProgress: song.audio.currentTime },
		}));
	};

	const onScrubEnd = (id: string) => {
		const song = songs[id];
		clearInterval(song.interval);
		song.interval = setInterval(
			() =>
				setSongs((current: any) => ({
					...current,
					[id]: { ...song, trackProgress: song.audio.currentTime },
				})),
			1000
		);
	};

	useEffect(() => {
		songsRef.current = songs;
	}, [songs]);

	useEffect(() => {
		return () => {
			Object.keys(songsRef.current).map((id: string) => {
				const song = songsRef.current[id];
				if (!song.audio) return;
				song.audio.pause();
				song.playing = false;
				clearInterval(song.interval);
			});
		};
	}, []);

	const song = songs[artist._id];
	const currentPercentage = song?.trackDuration
		? `${(song?.trackProgress / song?.trackDuration) * 100}%`
		: '0%';

	const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #f8835a55), color-stop(${currentPercentage}, #eee))
  `;

	return (
		<>
			<div className={classes.container}>
				<div className={classes.content}>
					<img
						className={classes.profileImage}
						src={
							artist.imageUrl ||
							// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/user.jpeg'
							'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/profile-pictures%2Fuser.jpeg?alt=media&token=9006fe66-7543-4f23-a489-06e8a932c026'
						}
					/>
					<div className={classes.info}>
						<h1>{artist.artistName}</h1>
						<hr />
						<p>{artist.bio}</p>

						{artist.sampleSongUrl ? (
							<div className={classes.sampleSong}>
								<p>Sample Song</p>
								<div className={classes.song}>
									<button
										onClick={(e) => {
											e.stopPropagation();
											song?.playing
												? pauseSong(artist._id)
												: playSong(artist._id);
										}}
									>
										<img src={song?.playing ? pause : play} alt="control" />
									</button>
									<input
										type="range"
										value={song?.trackProgress}
										step="1"
										min="0"
										max={song?.trackDuration}
										onChange={(e) => onScrub(artist._id, e.target.value)}
										onMouseUp={() => onScrubEnd(artist._id)}
										onKeyUp={() => onScrubEnd(artist._id)}
										style={{ background: trackStyling }}
									/>
								</div>
							</div>
						) : (
							<div className={classes.sampleSong}>
								<button disabled>Sample Song Not Available</button>
							</div>
						)}

						<div className={classes.links}>
							{artist.links?.facebook && (
								<a
									href={artist.links?.facebook}
									target="_blank"
									rel="noopener noreferrer"
								>
									{<img src={facebook} alt="website" />}
								</a>
							)}
							{artist.links?.twitter && (
								<a
									href={artist.links?.twitter}
									target="_blank"
									rel="noopener noreferrer"
								>
									{<img src={twitter} alt="website" />}
								</a>
							)}
							{artist.links?.instagram && (
								<a
									href={artist.links?.instagram}
									target="_blank"
									rel="noopener noreferrer"
								>
									{<img src={instagram} alt="website" />}
								</a>
							)}
							{artist.links?.youtube && (
								<a
									href={artist.links?.youtube}
									target="_blank"
									rel="noopener noreferrer"
								>
									{<img src={youtube} alt="website" />}
								</a>
							)}
							{artist.links?.spotify && (
								<a
									href={artist.links?.spotify}
									target="_blank"
									rel="noopener noreferrer"
								>
									{<img src={spotify} alt="website" />}
								</a>
							)}
						</div>
					</div>
				</div>

				{artist.reviews?.length !== 0 && (
					<div className={classes.reviews}>
						<h1>Reviews</h1>
						<hr />
						<div className={classes.items}>
							{artist.reviews?.map((review: any, i: number) => (
								<div className={classes.item} key={i}>
									<div className={classes.stars}>
										{Array.from(Array(parseInt(review.rating)).keys()).map(
											(i) => (
												<span key={i}>&#9733;</span>
											)
										)}
									</div>
									<p>{review.text}</p>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
}
