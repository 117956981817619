import classes from './ContactUs.module.scss';
import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';

export default function ContactUs() {
	return (
		<div className={classes.page}>
			<Navbar />
			<div className={classes.content}>
				<div className={classes.header}>
					<h1>Contact Us</h1>
				</div>
				<div className={classes.box}>
					<div>
						<h2>Address</h2>
						<p>295, Sanauli Road, Panipat, Haryana </p>
					</div>
					<div>
						<h2>Email</h2>
						<a href="mailto:info@melotales.com">info@melotales.com</a>
					</div>
					<div>
						<h2>Phone</h2>
						<a href="tel:+911807965763">+91 99961 20181</a>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
