import { Route, Routes, useLocation } from 'react-router-dom';

// libraries
import GoogleAnalytics from 'react-ga';

// pages
import Home from 'pages/Home';
import CreateSong from 'pages/CreateSong';
import CreateJingle from 'pages/CreateJingle';
import Artists from 'pages/Artists';
import TrackOrder from 'pages/TrackOrder';
import TipsFaqs from 'pages/TipsFaqs';
import ThankYou from 'pages/ThankYou';

// styles
import { useEffect } from 'react';
import Terms from 'pages/Terms';
import AboutUs from 'pages/AboutUs';
import ContactUs from 'pages/ContactUs';
import RefundPolicy from 'pages/RefundPolicy';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ViewArtist from 'pages/ViewArtist';
import ReactPixel from 'react-facebook-pixel';

GoogleAnalytics.initialize('UA-226089896-1');
GoogleAnalytics.initialize('G-J1JLQW50KV', {
  gaOptions: {
    name: 'tracking-id-2',
  },
});

function App() {
  const location = useLocation();

  useEffect(() => {
    const loadScript = (src: any) => {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
      });
    };

    loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }, []);

  // FB PIXEL ANALYTICS
  const options = {
    autoConfig: true,
    debug: false,
  };
  const advancedMatching: any = {}; // Use {} or undefined if no advanced matching data

  ReactPixel.init('126961626975498', advancedMatching, options);
  ReactPixel.pageView();
  ReactPixel.trackCustom(
    'track',
    window.location.pathname + window.location.search
  );

  // Initialize the second Facebook Pixel
  ReactPixel.init('1030313311810165', advancedMatching, options);
  ReactPixel.pageView();
  ReactPixel.trackCustom('track', window.location.pathname + window.location.search);

  useEffect(() => {
    GoogleAnalytics.pageview(window.location.pathname + window.location.search);
    GoogleAnalytics.pageview(
      window.location.pathname + window.location.search,
      ['tracking-id-2']
    );
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/create-song" element={<CreateSong />} />
      <Route path="/custom-song" element={<CreateJingle />} />
      <Route path="/artists" element={<Artists />} />
      <Route path="/artists/:id" element={<ViewArtist />} />
      <Route path="/track-order" element={<TrackOrder />} />
      <Route path="/tips-faqs" element={<TipsFaqs />} />
      <Route path="/terms-and-conditions" element={<Terms />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
}

export default App;
