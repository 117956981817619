import { useState, useEffect } from 'react';
import { getUSDPrice } from 'utils';
import classes from './Content.module.scss';

interface Step4Props {
	onNext: () => void;
	onBack: () => void;
	delivery: any;
	coupon: any;
	appliedCoupon: any;
	prices: any;
	onCouponApply: () => void;
	onCouponRemove: () => void;
	lengthKey: any;
}

export default function Step3(props: Step4Props) {
	const {
		delivery,
		onCouponApply,
		coupon,
		appliedCoupon,
		prices,
		onCouponRemove,
		lengthKey,
	} = props;
	const [usdPrice, setUsdPrice] = useState(0);

	const callPrice = async () => {
		const price = await getUSDPrice();
		setUsdPrice(price);
	};

	useEffect(() => {
		callPrice();
	}, []);
	let priceForLength = prices?.actual[lengthKey.value] || 0;
	let priceForDelivery = 0;

	let total = priceForLength + priceForDelivery;
	let discountValue = 0;
	if (appliedCoupon) {
		if (appliedCoupon.type === 'fixed') {
			discountValue = appliedCoupon.value;
		} else if (appliedCoupon.type === 'percentage') {
			discountValue = (total * appliedCoupon.value) / 100;
		}
	}

	total = total - discountValue;
	let remainingAmount = (total * 100) / 118;
	let CGST = total - remainingAmount;

	return (
		<div className={classes.container}>
			<div className={classes.checkout}>
				<div className={classes.question}>
					<h3>Checkout</h3>
					<p className={classes.subtext}>
						ONCE YOU CHECKOUT YOU CANNOT PRESS "BACK" TO MAKE EDITS. Please
						double check to make sure all of your information (including names
						and pronunciations) is correct, If you add incorrect information,
						you must email us with any edits within 30 minutes of placing your
						order.
					</p>
				</div>
			</div>

			{/* {!appliedCoupon?.value && (
        <div className={classes.question} style={{ marginBottom: 30 }}>
          <h3 style={{ marginBottom: 10 }}>Do you have a coupon?</h3>
          <div className={classes.couponInput}>
            <input
              value={coupon.value}
              onChange={coupon.onChange}
              placeholder="Enter your coupon code"
            />
            <button onClick={onCouponApply}>Apply</button>
          </div>
        </div>
      )} */}

			<div className={classes.pricingSummary}>
				<h2>Pricing Summary</h2>
				<div className={classes.splitUps}>
					{/* <div className={classes.splitUp}>
						<p>Price</p>
						<p>
							₹{remainingAmount.toLocaleString()} ($
							{(priceForLength * usdPrice).toFixed(2)}).
						</p>
					</div> */}

					{/* <div className={classes.splitUp}>
						<p>CGST (+18%)</p>
						<p>+ ₹{CGST.toLocaleString()}</p>
					</div> */}
					{appliedCoupon?.value && (
						<div className={classes.splitUp}>
							<p>
								Applied Coupon ({appliedCoupon.code})
								<span
									className={classes.removeCouponButton}
									onClick={onCouponRemove}
								>
									Remove
								</span>
							</p>

							<p>- ₹{discountValue?.toLocaleString()}</p>
						</div>
					)}
					<div className={classes.total}>
						<p>Total Price</p>
						<p>₹{total.toFixed(2)}</p>
					</div>
				</div>
			</div>

			<hr />
			<div className={classes.footer}>
				<button className={classes.moveNextButton} onClick={props.onBack}>
					Back
				</button>
				<button className={classes.moveNextButton} onClick={props.onNext}>
					Checkout
				</button>
				<p className={classes.termsandconditions}>
					By pressing "Checkout" you are agreeing to Melotales's Terms &
					Conditions
				</p>
			</div>
		</div>
	);
}
