import classes from './PickArtistBox.module.scss';
import play from 'assets/icons/play.svg';
import pause from 'assets/icons/pause.svg';
import { useEffect, useRef, useState } from 'react';

export default function PickArtistBox(props: any) {
	const { allArtists, selectedArtist } = props;
	const artist = allArtists.find((a: any) => a._id === selectedArtist.value);

	const [song, setSong] = useState<any>({});
	const songRef = useRef<any>({});
	const [isSongAvailable, setIsSongAvailable] = useState(false);

	useEffect(() => {
		let song: any = {};
		if (artist?.sampleSongUrl) {
			setIsSongAvailable(true);
		} else {
			setIsSongAvailable(false);
		}

		const audio = new Audio(artist?.sampleSongUrl);

		audio.onloadedmetadata = (a) => {
			setSong((current: any) => ({
				...current,
				trackDuration: audio.duration,
			}));
		};

		song = {
			audio,
			playing: false,
			trackProgress: 0,
			trackDuration: isNaN(audio.duration) ? 0 : audio.duration,
		};

		setSong(song);
	}, [selectedArtist.value]);

	const playSong = () => {
		if (!song.audio) return;
		song.audio.play();
		song.playing = true;

		const trackInterval = setInterval(() => {
			setSong((current: any) => ({
				...current,
				trackProgress: current.audio.currentTime,
				trackDuration: current.audio.duration,
			}));
		}, 1000);

		setSong((current: any) => ({
			...current,
			playing: true,
			interval: trackInterval,
		}));
	};

	const pauseSong = () => {
		if (!song.audio) return;
		song.audio.pause();
		song.playing = false;
		clearInterval(song.interval);
		setSong((current: any) => ({ ...current, playing: false }));
	};

	const onScrub = (value: any) => {
		clearInterval(song.interval);
		song.audio.currentTime = value;
		setSong((current: any) => ({
			...current,
			trackProgress: song.audio.currentTime,
		}));
	};

	const onScrubEnd = () => {
		clearInterval(song.interval);
		song.interval = setInterval(
			() =>
				setSong((current: any) => ({
					...current,
					trackProgress: song.audio.currentTime,
				})),
			1000
		);
	};

	useEffect(() => {
		songRef.current = song;
	}, [song]);

	useEffect(() => {
		return () => {
			const song = songRef.current;
			song.audio.pause();
			song.playing = false;
			clearInterval(song.interval);
		};
	}, [selectedArtist.value]);

	return (
		<div className={classes.pickArtistContainer}>
			<div className={classes.pickArtist}>
				<select value={selectedArtist.value} onChange={selectedArtist.onChange}>
					<option value="">Select your artist</option>
					{allArtists?.map((artist: any) => (
						<option key={artist?._id} value={artist?._id}>
							{artist?.artistName}
						</option>
					))}
				</select>

				<Song
					artist={artist}
					isAvailable={isSongAvailable}
					song={song}
					play={playSong}
					pause={pauseSong}
					onScrub={onScrub}
					onScrubEnd={onScrubEnd}
				/>
			</div>
		</div>
	);
}

const Song = (props: any) => {
	const currentPercentage = props.song?.trackDuration
		? `${(props.song?.trackProgress / props.song?.trackDuration) * 100}%`
		: '0%';
	const background = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #f8835a55), color-stop(${currentPercentage}, #eee))
`;

	return props.isAvailable ? (
		<div className={classes.sampleSong}>
			<p>{props.artist?.artistName}'s Sample Song</p>
			<div className={classes.player}>
				<img
					onClick={props.song?.playing ? props.pause : props.play}
					src={props.song?.playing ? pause : play}
					alt="control"
				/>

				<input
					type="range"
					value={props.song?.trackProgress}
					step="1"
					min="0"
					max={props.song?.trackDuration}
					className="progress"
					onChange={(e) => props.onScrub(e.target.value)}
					onMouseUp={props.onScrubEnd}
					onKeyUp={props.onScrubEnd}
					style={{ background }}
				/>
			</div>
		</div>
	) : (
		<button className={classes.sampleSongNotAvailable} disabled>
			Sample Song Not Available
		</button>
	);
};
