import { toast } from 'react-toastify';
import classes from './Content.module.scss';
import { useEffect, useState } from 'react';

const MAX_CHAR_LEN_FOR_NOTES = 1000;

interface Step2Props {
	onNext: () => void;
	onBack: () => void;
	from: any;
	to: any;
	ocassion: any;
	details: any;
	notes: any;
	user: any;
	phoneNumber: any;
	relation: any;
	coupon: any;
	state: any;
}

const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export default function Step3(props: Step2Props) {
	const {
		from,
		to,
		ocassion,
		details,
		notes,
		user,
		phoneNumber,
		relation,
		coupon,
		state,
	} = props;
	const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
		''
	);
	const [selectedState, setSelectedState] = useState<string | undefined>('');

	const countries: string[] = ['India', 'USA', 'OTHERS']; // Add more countries if needed

	const statesByCountry: { [key: string]: string[] } = {
		India: [
			'Andhra Pradesh',
			'Arunachal Pradesh',
			'Assam',
			'Bihar',
			'Chhattisgarh',
			'Goa',
			'Gujarat',
			'Haryana',
			'Himachal Pradesh',
			'Jharkhand',
			'Karnataka',
			'Kerala',
			'Madhya Pradesh',
			'Maharashtra',
			'Manipur',
			'Meghalaya',
			'Mizoram',
			'Nagaland',
			'Odisha',
			'Punjab',
			'Rajasthan',
			'Sikkim',
			'Tamil Nadu',
			'Telangana',
			'Tripura',
			'Uttar Pradesh',
			'Uttarakhand',
			'West Bengal',
			'Andaman and Nicobar Islands',
			'Chandigarh',
			'Dadra and Nagar Haveli and Daman and Diu',
			'Lakshadweep',
			'Delhi',
			'Puducherry',
		],
		USA: [
			'Alabama',
			'Alaska',
			'Arizona',
			'Arkansas',
			'California',
			'Colorado',
			'Connecticut',
			'Delaware',
			'Florida',
			'Georgia',
			'Hawaii',
			'Idaho',
			'Illinois',
			'Indiana',
			'Iowa',
			'Kansas',
			'Kentucky',
			'Louisiana',
			'Maine',
			'Maryland',
			'Massachusetts',
			'Michigan',
			'Minnesota',
			'Mississippi',
			'Missouri',
			'Montana',
			'Nebraska',
			'Nevada',
			'New Hampshire',
			'New Jersey',
			'New Mexico',
			'New York',
			'North Carolina',
			'North Dakota',
			'Ohio',
			'Oklahoma',
			'Oregon',
			'Pennsylvania',
			'Rhode Island',
			'South Carolina',
			'South Dakota',
			'Tennessee',
			'Texas',
			'Utah',
			'Vermont',
			'Virginia',
			'Washington',
			'West Virginia',
			'Wisconsin',
			'Wyoming',
		],
		OTHERS: [
			'Ontario',
			'Quebec',
			'British Columbia',
			'Alberta',
			'Manitoba',
			'Saskatchewan',
			'New South Wales',
			'Victoria',
			'Queensland',
			'Western Australia',
			'South Australia',
			'Tasmania',
			'England',
			'Scotland',
			'Wales',
			'Northern Ireland',
			'Bavaria',
			'North Rhine-Westphalia',
			'Baden-Württemberg',
			'Lower Saxony',
			'Hesse',
			'Saxony',
			'Île-de-France',
			'Provence-Alpes-Côte d Azur',
			'Auvergne-Rhône-Alpes',
			'Occitanie',
			'Hauts-de-France',
			'Brittany',
			'São Paulo',
			'Minas Gerais',
			'Rio de Janeiro',
			'Bahia',
			'Rio Grande do Sul',
			'Paraná',
			'Guangdong',
			'Shandong',
			'Henan',
			'Sichuan',
			'Jiangsu',
			'Hebei',
			'Tokyo',
			'Osaka',
			'Hokkaido',
			'Aichi',
			'Kanagawa',
			'Fukuoka',
		],
		// Add more countries and states if needed
	};

	const onNext = () => {
		if (
			!from.value ||
			!to.value ||
			!ocassion.value ||
			!details.value ||
			!user.value ||
			!phoneNumber.value ||
			!relation.value ||
			!state.value
		) {
			return toast.error('Please fill the form');
		}

		const isEmail = validateEmail(user.value);
		if (!isEmail) {
			return toast.error('Please enter a valid email address');
		}

		props.onNext();
	};

	return (
		<div className={classes.formContainer}>
			<div className={classes.details}>
				<div className={classes.question}>
					<h3 style={{ marginBottom: 10 }}>Who is the song from:</h3>
				</div>

				<div className={classes.form}>
					<div className={classes.inputs}>
						<input
							placeholder="What's your email?"
							value={user.value}
							onChange={user.onChange}
						/>
						<input
							placeholder="What's your phone number?"
							value={phoneNumber.value}
							onChange={phoneNumber.onChange}
						/>
					</div>
					<div className={classes.inputs}>
						<input
							placeholder="Who is gifting the song? Ex. Dhruv"
							value={from.value}
							onChange={from.onChange}
						/>
						<input
							placeholder="Who is the song for? Ex. Ananya"
							value={to.value}
							onChange={to.onChange}
						/>
					</div>
					<div className={classes.inputs}>
						<input
							placeholder="What's your relation? Ex. Husband, Wife, Boyfriend, Friend, Parents. etc"
							value={relation.value}
							onChange={relation.onChange}
						/>
					</div>
					<input
						placeholder="What's the occasion? Ex. Birthday, Anniversary, Wedding, Proposal or any Occation"
						value={ocassion.value}
						onChange={ocassion.onChange}
					/>
					{/* <input
						placeholder="Which Stateeee? (Ex. Delhi, UP, etc.)"
						value={state.value}
						onChange={state.onChange}
					/> */}

					<div className={classes.stateDropdown}>
						<label className={classes.stateDropdownlabel1}>
							Select Location:
						</label>

						<select
							className={classes.stateDropdownlabel}
							placeholder="Select Country"
							value={selectedCountry || ''}
							onChange={(e) => setSelectedCountry(e.target.value)}
						>
							<option value="" disabled>
								Select a country
							</option>
							{countries.map((country) => (
								<option key={country} value={country}>
									{country}
								</option>
							))}
						</select>
						<select
							className={classes.stateDropdownlabel}
							placeholder="Select State"
							value={state.value}
							onChange={state.onChange}
						>
							<option value="" disabled>
								Select a state
							</option>
							{selectedCountry &&
								statesByCountry[selectedCountry].map((state) => (
									<option key={state} value={state}>
										{state}
									</option>
								))}
						</select>
					</div>

					<textarea
						placeholder="Write your story in Hindi or English….
						e.g. Hey Ayaan, I can’t forget our college trip to Manali where we first met. I cherish all the the date nights and moments spent with you. You are my sunshine. I love you, wishing you a very happy birthday."
						value={details.value}
						onChange={details.onChange}
					/>
					{/* <div className={`${classes.inputs} ${classes.textareaInput}`}>
						<textarea
							placeholder="Write your Message"
							value={notes.value}
							onChange={notes.onChange}
							maxLength={MAX_CHAR_LEN_FOR_NOTES}
						/>
						<span>
							{notes.value?.length}/{MAX_CHAR_LEN_FOR_NOTES}
						</span>
					</div> */}
				</div>
			</div>
			<hr />
			<div className={classes.footer}>
				<button className={classes.moveNextButton} onClick={props.onBack}>
					Back
				</button>
				<button className={classes.moveNextButton} onClick={onNext}>
					Next
				</button>
			</div>
		</div>
	);
}
