import network from './network';

export const list = () => {
	return network.request.get({
		path: 'sample-songs/',
	});
};

const samplesongs = {
	list,
};

export default samplesongs;
