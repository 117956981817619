import api from 'api';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import classes from './Artist.module.scss';
import play from 'assets/icons/play.svg';
import pause from 'assets/icons/pause.svg';
import { useNavigate } from 'react-router-dom';

function shuffleArray(array: any[]) {
	const arr = [...array];
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arr[i], arr[j]] = [arr[j], arr[i]];
	}
	return arr;
}

export default function ArtistList() {
	const [artists, setArtists] = useState<any>([]);
	const [songs, setSongs] = useState<any>({});
	const songsRef = useRef<any>({});

	useEffect(() => {
		const fetchArtist = async () => {
			const { success, error, response } = await api.artists.list();
			if (!success) return toast.error(error?.message);
			setArtists(shuffleArray(response.artists));

			const song: any = {};
			response.artists.map((a: any) => {
				const audio = new Audio(a.sampleSongUrl);
				song[a._id] = {
					audio,
					playing: false,
					trackProgress: 0,
					trackDuration: audio.duration,
				};
			});
			setSongs(song);
		};

		fetchArtist();
	}, []);

	const pauseAllSongs = () => {
		Object.keys(songs).map((id: string) => {
			const song = songs[id];
			if (!song.audio) return;
			song.audio.pause();
			song.playing = false;
			clearInterval(song.interval);
			setSongs({ ...songs, [id]: song });
		});
	};

	const playSong = (id: string) => {
		pauseAllSongs();
		const song = songs[id];
		if (!song.audio) return;
		song.audio.play();
		song.playing = true;

		const trackInterval = setInterval(() => {
			setSongs((current: any) => ({
				...current,
				[id]: {
					...current[id],
					trackProgress: current[id].audio.currentTime,
					trackDuration: current[id].audio.duration,
				},
			}));
		}, 1000);

		setSongs((current: any) => ({
			...current,
			[id]: { ...current[id], playing: true, interval: trackInterval },
		}));
	};

	const pauseSong = (id: string) => {
		const song = songs[id];
		if (!song.audio) return;
		song.audio.pause();
		song.playing = false;
		clearInterval(song.interval);
		setSongs((current: any) => ({
			...current,
			[id]: { ...current[id], playing: false },
		}));
	};

	const onScrub = (id: string, value: any) => {
		const song = songs[id];
		clearInterval(song.interval);
		song.audio.currentTime = value;
		setSongs((current: any) => ({
			...current,
			[id]: { ...song, trackProgress: song.audio.currentTime },
		}));
	};

	const onScrubEnd = (id: string) => {
		const song = songs[id];
		clearInterval(song.interval);
		song.interval = setInterval(
			() =>
				setSongs((current: any) => ({
					...current,
					[id]: { ...song, trackProgress: song.audio.currentTime },
				})),
			1000
		);
	};

	useEffect(() => {
		songsRef.current = songs;
	}, [songs]);

	useEffect(() => {
		return () => {
			Object.keys(songsRef.current).map((id: string) => {
				const song = songsRef.current[id];
				if (!song.audio) return;
				song.audio.pause();
				song.playing = false;
				clearInterval(song.interval);
			});
		};
	}, []);

	return (
		<>
			<div className={classes.headerContainer}>
				<h1>Artists & Sample Songs</h1>
			</div>
			<div className={classes.container}>
				<div className={classes.cards}>
					{artists.map((artist: any) => (
						<Card
							{...artist}
							song={songs[artist._id]}
							playSong={playSong}
							pauseSong={pauseSong}
							onScrub={onScrub}
							onScrubEnd={onScrubEnd}
						/>
					))}
				</div>
			</div>
		</>
	);
}

const Card = (props: any) => {
	const navigate = useNavigate();

	const currentPercentage = props.song?.trackDuration
		? `${(props.song?.trackProgress / props.song?.trackDuration) * 100}%`
		: '0%';

	const background = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #f8835a55), color-stop(${currentPercentage}, #eee))
  `;

	const navigateToArtist = () => {
		navigate(`/artists/${props._id}`);
	};

	return (
		<div className={classes.card} onClick={navigateToArtist}>
			<img
				src={
					props?.imageUrl ||
					// 'https://s3.ap-south-1.amazonaws.com/media.melotales.com/user.jpeg'
					'https://firebasestorage.googleapis.com/v0/b/melotales.appspot.com/o/profile-pictures%2Fuser.jpeg?alt=media&token=9006fe66-7543-4f23-a489-06e8a932c026'
				}
			/>
			<p className={classes.name}>{props.artistName}</p>
			{props.sampleSongUrl ? (
				<div className={classes.sampleSong}>
					<button
						onClick={(e) => {
							e.stopPropagation();
							props.song?.playing
								? props.pauseSong(props._id)
								: props.playSong(props._id);
						}}
					>
						<img src={props.song?.playing ? pause : play} alt="control" />
					</button>
					<input
						type="range"
						value={props.song?.trackProgress}
						step="1"
						min="0"
						max={props.song?.trackDuration}
						onChange={(e) => props.onScrub(props._id, e.target.value)}
						onMouseUp={() => props.onScrubEnd(props._id)}
						onKeyUp={() => props.onScrubEnd(props._id)}
						style={{ background }}
						onClick={(e) => e.stopPropagation()}
					/>
				</div>
			) : (
				<button className={classes.sampleSong} disabled>
					Sample Song Not Available
				</button>
			)}
		</div>
	);
};
