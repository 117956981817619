// styles
import Carousel from 'react-alice-carousel';
import classes from './Testimonial.module.scss';
import { Link } from 'react-router-dom';

import ahaan from 'assets/images/testimonial/ahaan.jpg';
import dhairya from 'assets/images/testimonial/dhairya.jpg';
import vrinda from 'assets/images/testimonial/vrinda.jpg';
import { link } from 'fs';

const testimonials = [
	{
		name: 'Dhairya',
		image: dhairya,
		rating: 5,
		review:
			'Gifting something unique and special to my partner at our Wedding was the toughest task I went through. I wanted her to feel special and personal. Melotales became my choice then. Creating a customised gift for my partner is the perfect decision I made. With all the memorable moments of our love journey, Melotales personalised the beautiful melting musical gift for my wife. Listening to that song was like living all those moments again for a few minutes with happy tears. I Thank melotales.com for this precious gift.',
	},
	{
		name: 'Ahaan',
		image: ahaan,
		rating: 5,
		review:
			'I  know that my dream girl was a music lover. I penned all my feelings for her. This professional team made that as an amazing personalised gift for my lover. It was a kind of dream proposal. Customised musical gifts were out of box thinking, I felt. Melotales was very realistic with the lyrics and music composition. The singers were just awesome. I felt extremely happy with the team of Melotales.',
	},
	{
		name: 'Vrinda',
		image: vrinda,
		rating: 5,
		review:
			"This is my mother's 50th birthday. My brother and I wanted to give her the best birthday gift ever. They just asked me to give a few personal details and specific notes if needed. Rest all was taken care of by them. Finally what we had was a flaunt less magical music present for her birthday. Momma felt touched and happy. For the best gifting ideas, I would suggest Melotales.",
	},
];

export default function Testimonial() {
	const items = testimonials.map((item, index: number) => (
		<div className={classes.testimonial} key={item.name}>
			<div className={classes.user}>
				<img src={item.image} alt={item.name} />
				<div className={classes.userDetails}>
					<p className={classes.name}>{item.name}</p>
					<div>
						{[...Array(5)].map((i) => (
							<span key={i}>&#9733;</span>
						))}
					</div>
				</div>
			</div>
			<p className={classes.description}>{item.review}</p>
		</div>
	));
	return (
		<div className={classes.testimonials}>
			<div className={classes.left}>
				<h2 className={classes.title}>See what our customers Say about Us?</h2>
				<div className={classes.divider} />
				{/* <div style={{ width: '78vw', overflow: 'hidden' }}>
					<Carousel
						infinite
						items={items}
						renderDotsItem={() => null}
						renderPrevButton={() => (
							<p className={classes.controlButton}>{'<'}</p>
						)}
						renderNextButton={() => (
							<p className={classes.controlButton}>{'>'}</p>
						)}
					/>
				</div> */}
				<div className={classes.testimonialscont}>
					<div className={classes.testimonialsImgsCont}>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385590/melotales/ok7iq3fngsiujsf5hvxp.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385590/melotales/ot9xhutjelseiljet1ap.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385590/melotales/f3v6ff5fwlrng3m9ce9z.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385590/melotales/xgt1edueaccxqpgzxnds.jpg"
							alt=""
						/>

						<div className={classes.testimonialsBtnctr}>
							<Link to="/custom-song">
								<button className={classes.button}>Create My Song</button>
							</Link>
						</div>

						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385590/melotales/jeureldxiia7oinvuf6q.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385590/melotales/jhns0oogjoeeyzjdfsqz.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385589/melotales/yytco07evvdowahbiq6q.jpg"
							alt=""
						/>
						<div className={classes.testimonialsBtnctr}>
							<Link to="/custom-song">
								<button className={classes.button}>Create My Song</button>
							</Link>
						</div>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385589/melotales/yivxp8e3mhyv1vszjvkh.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385589/melotales/d22tk8qjumgkb3qoe4kn.jpg"
							alt=""
						/>

						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385589/melotales/xj9u3tpm46754bk5bjpj.jpg"
							alt=""
						/>
						<img
							className={classes.testimonialsImgs}
							src="https://res.cloudinary.com/diuoze6fm/image/upload/v1708385589/melotales/wzewtojxy6cy8pbmb3vf.jpg"
							alt=""
						/>
						<div className={classes.testimonialsBtnctr}>
							<Link to="/custom-song">
								<button className={classes.button}>Create My Song</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
