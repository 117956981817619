import { useState } from 'react';

export default function useInput(initialValue: any = null) {
	const [value, setValue] = useState(initialValue);

	const set = (v: any) => setValue(v);
	const reset = () => setValue('');
	const onChange = (e: any) => setValue(e.target.value);

	return { value, set, reset, onChange };
}
