import axios, { Method } from 'axios';
import { ERROR_MESSAGES } from 'api/errorcodes';

const BASE_URL = process.env.REACT_APP_API_URL;

export const resolveURL = (path: string) => {
	return BASE_URL + path;
};

export const resolveErrorMessage = (code: string) => {
	return {
		code,
		message: ERROR_MESSAGES[code] || 'Something went wrong',
	};
};

interface RequestOptions {
	path: string;
	body?: any;
	headers?: any;
	method?: Method;
	auth?: boolean;
	resModifier?: (res: any) => any;
}

interface RequestResponse {
	success: boolean;
	response?: any;
	message?: string;
	error?: { code: string; message: string };
}

const request = async (options: RequestOptions): Promise<RequestResponse> => {
	try {
		let {
			path,
			body,
			headers,
			method,
			auth = true,
			resModifier: responseModifier = (res: any) => res,
		} = options;

		if (auth) {
			const token = window.localStorage.getItem('authToken');
			headers = {
				...headers,
				Authorization: `Bearer ${token}`,
			};
		}

		const response = await axios({
			url: resolveURL(path),
			method,
			data: body,
			headers,
		});
		const { success, code, data } = response.data;

		if (success) {
			return { success: true, error: code, response: responseModifier(data) };
		} else {
			throw resolveErrorMessage(code);
		}
	} catch (error: any) {
		console.log(error);
		return {
			success: false,
			error: {
				code: error.code || error.response.data.code,
				message:
					resolveErrorMessage(error.response.data.code).message ||
					error.response.data.message,
			},
			response: error.response.data,
		};
	}
};

const network = {
	request: {
		post: (options: RequestOptions) => request({ ...options, method: 'POST' }),
		get: (options: RequestOptions) => request({ ...options, method: 'GET' }),
		delete: (options: RequestOptions) =>
			request({ ...options, method: 'DELETE' }),
		put: (options: RequestOptions) => request({ ...options, method: 'PUT' }),
		patch: (options: RequestOptions) =>
			request({ ...options, method: 'PATCH' }),
	},
};

export default network;
