import Navbar from 'components/Common/Navbar';
import Wizard from 'components/CreateJingle/Wizard';
import Footer from 'components/Common/Footer';
import MetaData from 'utils/MetaData';

export default function CreateSong() {
	return (
		<div>
			<MetaData
				title="Custom Song | Personalized Song Gifts - Melotales"
				keywords=""
				description="Looking for best Jingles for Ads? Melotales provide all kind of advertisement jingles with Personalized Song Gifts. Get Most Memorable Advertising Jingles."
			/>
			<Navbar />
			<Wizard />
			<Footer />
		</div>
	);
}
