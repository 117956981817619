import network from './network';

export const get = () => {
	return network.request.get({ path: 'prices' });
};

const prices = {
	get,
};

export default prices;
