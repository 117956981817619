// hooks
import { useEffect, useState } from 'react';
import useStage from 'hooks/useStage';
import useInput from 'hooks/useInput';

// libraries
import classNames from 'classnames';
import { toast } from 'react-toastify';

// components
import Step1 from '../StepContent/Step1';
import Step2 from '../StepContent/Step2';
import Step3 from '../StepContent/Step3';
import Step4 from '../StepContent/Step4';

// styles
import classes from './Wizard.module.scss';

// config
import { Deliveries, Languages, Moods, jingleLengths } from 'config/orders';

// helpers
import api from 'api';

// assetls
import melotalesLogo from 'assets/logos/melotales.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validateEmail } from 'utils';

enum STAGES {
	STEP1 = 1,
	STEP2 = 2,
	STEP3 = 3,
	STEP4 = 4,
}

export default function Wizard() {
	const navigate = useNavigate();
	const stage = useStage({
		initial: STAGES.STEP1,
		stages: STAGES,
		callback: () => window.scrollTo({ top: 0 }),
	});

	const [prices, setPrices] = useState();
	const [appliedCoupon, setAppliedCoupon] = useState<any>({});

	const [searchParams] = useSearchParams();
	const _plan = searchParams.get('plan');

	const plan =
		!_plan || (_plan !== 'pro' && _plan !== 'premium') ? null : _plan;

	const user = useInput();
	const phoneNumber = useInput();
	const language = useInput(Languages[1].value);
	const pickArtist = useInput('yes');
	const mood = useInput(Moods[0].value);
	const delivery = useInput(Deliveries[0].value);
	const from = useInput();
	const to = useInput();
	const relation = useInput();
	const ocassion = useInput();
	const details = useInput();
	const notes = useInput('');
	const artist = useInput();
	const coupon = useInput();
	const state = useInput('');
	const artistType = useInput('');
	const length = useInput(
		plan
			? jingleLengths.filter((jingle) => jingle.plan === plan)[0].value
			: jingleLengths[0].value
	);
	const lengthKey = useInput(
		plan
			? jingleLengths.filter((jingle) => jingle.plan === plan)[0].key
			: jingleLengths[0].key
	);

	useEffect(() => {
		const fetchPrices = async () => {
			const { success, response } = await api.prices.get();
			if (success) setPrices(response.prices);
		};

		fetchPrices();
	}, []);

	const onCouponApply = async () => {
		if (!coupon.value) return toast.error('Please enter a coupon code');
		const { success, error, response } = await api.coupons.get(
			user.value,
			coupon.value
		);
		if (!success) return toast.error(error?.message);
		setAppliedCoupon(response);
	};

	const onCouponRemove = () => {
		setAppliedCoupon({});
		coupon.set('');
	};

	const onStep2Next = () => {
		if (
			!from.value ||
			!to.value ||
			!ocassion.value ||
			!user.value ||
			!phoneNumber.value ||
			!relation.value ||
			!state.value
		) {
			return toast.error('Please fill the form');
		}

		const isEmail = validateEmail(user.value);
		if (!isEmail) {
			return toast.error('Please enter a valid email address');
		}

		stage.set.STEP3();
	};

	const onCheckout = async () => {
		const body = {
			user: user.value,
			phoneNumber: phoneNumber.value,
			language: language.value,
			artist: pickArtist.value === 'yes' ? artist.value : null,
			mood: mood.value,
			delivery: delivery.value,
			from: from.value,
			to: to.value,
			relation: relation.value,
			ocassion: ocassion.value,
			state: state.value,
			details: 'hi',
			notes: notes.value || 'NA',
			coupon: appliedCoupon?.code?.toUpperCase(),
			songType: 'jingle',
			artistType: pickArtist.value === 'yes' ? artistType.value : null,
			length: length.value,
			lengthKey: lengthKey.value,
		};

		const { success, error, response } = await api.orders.create(body);
		if (!success) return toast.error(error?.message);

		const options = {
			key: process.env.REACT_APP_RAZORPAY_KEY,
			currency: response.transaction.currency,
			amount: response.transaction.amount,
			name: 'Melotales',
			image: melotalesLogo,
			order_id: response.transaction.id,
			notes: {
				length: length.value,
				lengthKey: lengthKey.value,
			},
			handler: () => {
				navigate('/thank-you');
			},
		};

		const paymentObject = new (window as any).Razorpay(options);
		await paymentObject.open();
	};

	let stageContent = null;
	switch (stage.current) {
		case STAGES.STEP1:
			stageContent = (
				<Step1
					onNext={() => {
						if (!artistType.value) {
							return toast.error('Please select an Artist.');
						}
						stage.set.STEP2();
					}}
					language={language}
					pickArtist={pickArtist}
					artistType={artistType}
				/>
			);
			break;

		case STAGES.STEP2:
			stageContent = (
				<Step2
					prices={prices}
					onNext={onStep2Next}
					onBack={stage.set.STEP1}
					details={details}
					notes={notes}
					user={user}
					mood={mood}
					from={from}
					to={to}
					relation={relation}
					ocassion={ocassion}
					phoneNumber={phoneNumber}
					coupon={coupon}
					delivery={delivery}
					state={state}
					length={length}
					lengthKey={lengthKey}
				/>
			);
			break;

		case STAGES.STEP3:
			stageContent = (
				<Step3
					prices={prices}
					onNext={onCheckout}
					onBack={stage.set.STEP2}
					delivery={delivery}
					coupon={coupon}
					onCouponApply={onCouponApply}
					appliedCoupon={appliedCoupon}
					onCouponRemove={onCouponRemove}
					lengthKey={lengthKey}
				/>
			);
			break;
		case STAGES.STEP4:
			stageContent = <Step4 />;
			break;

		default:
			stageContent = null;
	}

	return (
		<div className={classes.container}>
			<div className={classes.wizard}>
				<div className={classes.left}>
					<div className={classes.header}>
						<div
							style={{
								visibility:
									stage.current === STAGES.STEP4 ? 'hidden' : 'visible',
							}}
						>
							<h2 className={classes.title}>
								Let's Build you a Customized song!*
							</h2>
							<p className={classes.helpText}>
								* Don't leave this page while you're building your song or your
								info will be lost.
							</p>
						</div>
					</div>
					<div className={classes.stage}>{stageContent}</div>
				</div>
				<div className={classes.right}>
					<div className={classes.steps}>
						<WizardStep
							count={1}
							active={stage.current === STAGES.STEP1}
							completed={stage.current > STAGES.STEP1}
							onClick={stage.set.STEP1}
							title="Song Whereabouts"
							description="Firstly, select the language and the artist that you prefer."
						/>
						<WizardStep
							count={2}
							active={stage.current === STAGES.STEP2}
							completed={stage.current > STAGES.STEP2}
							onClick={() => {
								if (!artistType.value) {
									return toast.error('Please select an Artist.');
								}
								stage.set.STEP2();
							}}
							title="Personal Details"
							description="Who’s the song for? Add your special moments and you can also add custom notes for the artist."
						/>
						<WizardStep
							count={3}
							active={stage.current === STAGES.STEP3}
							completed={stage.current > STAGES.STEP3}
							onClick={onStep2Next}
							title="Billing"
							description="We promise you timely delivery of your personalized musical gift."
						/>
						{/* <WizardStep
              count={4}
              active={stage.current === STAGES.STEP4}
              completed={stage.current > STAGES.STEP4}
              title="Order Status"
            /> */}
					</div>
				</div>
			</div>
		</div>
	);
}

interface WizardStepProps {
	count: number;
	title: string;
	description?: string;
	active?: boolean;
	completed?: boolean;
	onClick?: () => void;
}

const WizardStep = (props: WizardStepProps) => {
	return (
		<div className={classes.step}>
			<div
				onClick={props.onClick}
				style={{ cursor: props.onClick ? 'pointer' : 'default' }}
				className={classNames(classes.count, {
					[classes.active]: props.active,
					[classes.completed]: props.completed,
				})}
			>
				<span>{props.count}</span>
			</div>
			<div className={classes.stepDescription}>
				<p className={classes.title}>{props.title}</p>
				{props.description && (
					<p className={classes.text}>{props.description}</p>
				)}
			</div>
		</div>
	);
};
