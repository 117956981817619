import classNames from 'classnames';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';
import useInput from 'hooks/useInput';
import classes from './Content.module.scss';
import { Deliveries, Lengths, Moods } from 'config/orders';
import { getUSDPrice } from 'utils';
import { useEffect, useState } from 'react';

interface Step2Props {
	onNext: () => void;
	onBack: () => void;
	mood: any;
	length: any;
	delivery: any;
	prices: any;
	lengthKey: any;
}

export default function Step2(props: Step2Props) {
	const { mood, length, delivery, prices, lengthKey } = props;
	const [usdPrice, setUsdPrice] = useState(0);
	console.log(prices);

	const callPrice = async () => {
		const price = await getUSDPrice();
		setUsdPrice(price);
	};

	useEffect(() => {
		callPrice();
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.mood}>
				<div className={classes.question}>
					<h3>How about the mood?</h3>
					<p className={classes.subtext}>
						This gives the artist some extra inspiration for your song, to
						better fit your story.
					</p>
				</div>
				<div className={classes.options}>
					{Moods.map((option) => (
						<div
							key={option.value}
							className={classNames(classes.option, {
								[classes.selected]: mood.value === option.value,
							})}
							onClick={() => mood.set(option.value)}
						>
							<label htmlFor={option.value}>{option.label}</label>
							<input
								type="radio"
								name="mood"
								id={option.value}
								value={option.value}
								checked={mood.value === option.value}
								onChange={mood.onChange}
							/>
						</div>
					))}
				</div>
			</div>

			<div className={classes.songlength}>
				<div className={classes.question}>
					<h3>How long of a song?</h3>
					<p className={classes.subtext}>All rates are inclusive of GST</p>
				</div>
				<div className={classes.options}>
					{Lengths.map((option) => (
						<div
							key={option.value}
							onClick={() => {
								lengthKey.set(option.key);
								length.set(option.value);
							}}
							className={classNames(classes.option, {
								[classes.selected]: length.value === option.value,
							})}
						>
							<label htmlFor={option.value}>
								<span>
									{option.label}{' '}
									{option.label === '1'
										? 'minute'
										: option.label === '30'
										? 'seconds'
										: 'minutes'}
								</span>
								<div className={classes.prices}>
									{prices?.fake[option.key] ? (
										<span className={classes.strikeThroughPrice}>
											₹{prices.fake[option.key]}
										</span>
									) : null}
									<span className={classes.price}>
										₹ {prices?.actual[option.key]} ($
										{(prices.actual[option.key] * usdPrice).toFixed(2)})
									</span>
								</div>
							</label>
							<input
								type="radio"
								id={option.value}
								value={option.value}
								checked={length.value === option.value}
								onChange={length.onChange}
							/>
							<MusicIcon />
						</div>
					))}
				</div>
			</div>
			<div className={classes.songDelivery}>
				<div className={classes.question}>
					<h3>When can we deliver the song?</h3>
				</div>
				<div className={classes.options}>
					{Deliveries.map((option) => (
						<div
							key={option.value}
							onClick={() => delivery.set(option.value)}
							className={classNames(classes.option, {
								[classes.selected]: delivery.value === option.value,
							})}
						>
							<label htmlFor={option.value}>
								<span>{option.label}</span>
								<div className={classes.prices}>
									{prices?.fake[option.key] ? (
										<span className={classes.strikeThroughPrice}>
											₹{prices.fake[option.key]}
										</span>
									) : null}
									<span className={classes.price}>
										₹{prices.actual[option.key]} (${' '}
										{(prices.actual[option.key] * usdPrice).toFixed(2)})
									</span>
								</div>
							</label>
							<input
								type="radio"
								id={option.value}
								value={option.value}
								checked={delivery.value === option.value}
								onChange={delivery.onChange}
							/>
						</div>
					))}
				</div>
			</div>
			<hr />
			<div className={classes.footer}>
				<button className={classes.moveNextButton} onClick={props.onBack}>
					Back
				</button>
				<button className={classes.moveNextButton} onClick={props.onNext}>
					Next
				</button>
			</div>
		</div>
	);
}
