import classNames from 'classnames';
import { Languages } from 'config/orders';
import { useEffect, useState } from 'react';
import PickArtistBox from '../PickArtistBox';
import classes from './Content.module.scss';

interface Step1Props {
	onNext: () => void;
	allArtists: any;
	selectedArtist: any;
	language: any;
	pickArtist: any;
}

export default function Step1(props: Step1Props) {
	const { allArtists, selectedArtist, language, pickArtist } = props;

	const [filteredArtists, setFilteredArtists] = useState(allArtists);

	useEffect(() => {
		setFilteredArtists(
			allArtists.filter((artist: any) =>
				artist.languages.includes(language.value)
			)
		);
	}, [language]);

	return (
		<div className={classes.container}>
			<div className={classes.language}>
				<div className={classes.question}>
					<h3>Choose your Language</h3>
				</div>
				<div className={classes.options}>
					{Languages.map((option) => (
						<div
							onClick={() => language.set(option.value)}
							className={classNames(classes.option, {
								[classes.selected]: language.value === option.value,
							})}
						>
							<label htmlFor={option.value}>{option.label}</label>
							<input
								type="radio"
								name="language"
								id={option.value}
								value={option.value}
								onChange={language.onChange}
								checked={option.value === language.value}
							/>
						</div>
					))}
				</div>
			</div>

			<div className={classes.selectArtist}>
				<div className={classes.question}>
					<h3>Would you like to select your artist?</h3>
					<p className={classes.subtext}>
						This sample does not reflect what your song will sound like.
					</p>
				</div>

				<div className={classes.options}>
					<div className={classes.option} onClick={() => pickArtist.set('no')}>
						<input
							type="radio"
							id="no"
							name="pickArtist"
							value="no"
							onChange={pickArtist.onChange}
							checked={pickArtist.value === 'no'}
						/>
						<label htmlFor="no">Let Melotales choose the best Artist</label>
					</div>

					<div className={classes.option} onClick={() => pickArtist.set('yes')}>
						<input
							type="radio"
							id="yes"
							name="pickArtist"
							value="yes"
							onChange={pickArtist.onChange}
							checked={pickArtist.value === 'yes'}
						/>
						<label htmlFor="yes">Yes. I'd like to select my artist</label>
					</div>
				</div>
				{pickArtist.value === 'yes' && (
					<PickArtistBox
						allArtists={filteredArtists}
						selectedArtist={selectedArtist}
					/>
				)}

				<hr />
				<div className={classes.footer}>
					<button className={classes.moveNextButton} onClick={props.onNext}>
						Next
					</button>
				</div>
			</div>
		</div>
	);
}
