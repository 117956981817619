import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = ({
	title = 'Musical Gifts | Personalized Custom Song - Melotales',
	keywords = 'blank',
	description = 'blank',
}) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords} />
				<link rel="canonical" href={window.location.href}></link>
			</Helmet>
		</>
	);
};

export default MetaData;
