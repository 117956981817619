import Facebook from 'assets/logos/facebook.png';
import Instagram from 'assets/logos/instagram.png';
import LinkedIn from 'assets/logos/linkedin.png';
import twitter from 'assets/logos/twitter.png';
import { Link } from 'react-router-dom';
import classes from './Footer.module.scss';

export default function Footer() {
	const scrollToTop = () => window.scrollTo(0, 0);
	return (
		<div className={classes.footer}>
			<hr />
			<div className={classes.bottom}>
				<div className={classes.links}>
					<Link onClick={scrollToTop} to="/about-us">
						About Us
					</Link>
					<Link onClick={scrollToTop} to="/terms-and-conditions">
						Terms and Conditions
					</Link>
					<Link onClick={scrollToTop} to="/privacy-policy">
						Privacy Policy
					</Link>
					<Link onClick={scrollToTop} to="/refund-policy">
						Refund Policy
					</Link>
					<Link onClick={scrollToTop} to="/contact-us">
						Contact Us
					</Link>
				</div>
				<div className={classes.socialLinks}>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://twitter.com/melotalesmusic"
					>
						<img src={twitter} alt="twitter" />
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.facebook.com/Melotalesmusic"
					>
						<img src={Facebook} alt="facebook" />
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://instagram.com/melotales.com_?igshid=YmMyMTA2M2Y="
					>
						<img src={Instagram} alt="instagram" />
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.linkedin.com/company/melotales-music/"
					>
						<img src={LinkedIn} alt="linkedin" />
					</a>
				</div>
			</div>
		</div>
	);
}
