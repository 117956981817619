import Navbar from 'components/Common/Navbar';
import Footer from 'components/Common/Footer';
import ArtistView from 'components/Artists/View';

export default function ViewArtist() {
	return (
		<div>
			<Navbar />
			<ArtistView />
			<Footer />
		</div>
	);
}
